import { useLayoutEffect } from 'react'
import { MapFeatureInfo } from 'ui-components'
import {
  setMapData,
  clearSelectedFeature,
  useFeatureInfo
} from '~map-feature-info/states/map'
import { useDispatch } from '~map-feature-info/states'

import { Map } from '~map-feature-info/types/__generated/gql/graphql'

type Props = {
  isEmbed: boolean
  map: Map
  title: string
  open: boolean
  onClose: () => void
}

const App = (props: Props) => {
  const { map, open, title, onClose, isEmbed = false, ...rest } = props

  const dispatch = useDispatch()
  const feature = useFeatureInfo()

  useLayoutEffect(() => {
    dispatch(setMapData(map))
  }, [dispatch, map])

  const handleCloseEnd = () => {
    dispatch(clearSelectedFeature())
  }

  return (
    <MapFeatureInfo
      {...rest}
      isEmbed={isEmbed}
      title={title}
      feature={feature}
      open={open}
      onClose={onClose}
      onCloseEnd={handleCloseEnd}
    />
  )
}

export { App }
