import _ from 'lodash'
import { SeoProps } from 'ui-components'
import { getMapThumbnailLink } from 'utils'

const generateOpenGraph = (baseUrl, map, slug: string[] = []): SeoProps => {
  try {
    if (!map) throw new Error('Map data is required to generate open graph')

    const httpProtocol =
      process.env.NODE_ENV === 'development' ? 'http://' : 'https://'

    const {
      id,
      dataId,
      name,
      mapTagCollection: { items }
    } = map

    const thumbnailUrl = getMapThumbnailLink(map)
    const mapTitleName = slug[1] ? `${name} : ${slug[1]}` : name
    const mapName = _.toLower(name).replace(/\s+/g, '-')
    slug[0] = mapName

    const coverageTag = items.find((item) => item.tag.type === 'coverageArea')
    const featureTypeTag = items.find((item) => item.tag.type === 'featureType')
    const topicTags = items
      .filter((item) => item.tag.type === 'topic')
      .map((item) => item.tag.name)
    const description =
      coverageTag || featureTypeTag
        ? `An interactive map of ${coverageTag?.tag?.name} by ${
            featureTypeTag?.tag?.name
          } that shows ${mapTitleName} covering ${topicTags.join(',')}`
        : `Explore and interact with the map ${mapTitleName}. Visualize the data, uncover insights, and easily share your findings.`

    return {
      url:
        httpProtocol + baseUrl + `/map/${id}/${mapName}/${_.toString(slug[1])}`,
      title: `${mapTitleName} - interactive maps`,
      description,
      image: {
        width: 1200,
        height: 630,
        url: thumbnailUrl,
        secureUrl: thumbnailUrl,
        type: 'image/png',
        alt: 'Interactive Map from mapstack'
      }
    }
  } catch (error) {
    console.warn(error)
    return {}
  }
}

export { generateOpenGraph }
export default generateOpenGraph
