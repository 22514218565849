import { prettyNums } from '../../../../../data-utils/prettyNums/index';
import { Jenks, Legend, LegendOptions } from '../../../../types';
import { DEFAULT_JENKS_CLASS_BREAKS } from '../../../constants';

function generateLabel(jenks: Jenks, index: number): string {
  // if index wold show 2-2, only show 2
  if (jenks[index] == jenks[index + 1]) return prettyNums(jenks[index]!)
  else {
    // else show 2-3
    return `${prettyNums(jenks[index]!)} - ${prettyNums(jenks[index + 1]!)}`
  }
}

export function generateLegend(
  jenks: Jenks,
  colourPalette: string[],
  title: string
): Legend {
  const classBreakDescriptions: LegendOptions[] = []

  // handle edge case of single class
  if (jenks.length == 1 && colourPalette[0])
    return {
      title: title,
      classes: [
        {
          value: colourPalette[0],
          key: 'fill',
          label: prettyNums(jenks[0]!)
        }
      ]
    }

  // TODO: This is a temporary fix to handle the case where the number of classes less than or equal to DEFAULT_JENKS_CLASS_BREAKS=7
  const isOverLimit = jenks.length > DEFAULT_JENKS_CLASS_BREAKS
  const range = isOverLimit ? jenks.length - 1 : jenks.length

  for (let i = 0; i < range; i++) {
    const colourExists = colourPalette[i] || colourPalette[i] !== undefined

    if (!colourExists) {
      console.error(`Unable to get ${i} from colour palette ${colourPalette}`)
      continue
    }

    // TODO: This is a temporary fix to handle the case where the number of classes less than or equal to DEFAULT_JENKS_CLASS_BREAKS=7
    const label: string = isOverLimit
      ? generateLabel(jenks, i)
      : prettyNums(jenks[i]!)

    const colour: string | undefined = colourPalette[i]
    if (!colour) {
      throw new Error('Unable to find colour')
    }
    const legendEntry: LegendOptions = {
      value: colour,
      key: 'fill',
      label: label
    }
    classBreakDescriptions.push(legendEntry)
  }

  const legend: Legend = {
    title: title,
    classes: classBreakDescriptions
  }

  return legend
}
