import { castArray } from 'lodash'
import * as qs from 'qs'

interface GetSearchQueryParams {
  sort?: string
  tags?: string[]
  from?: number
  size?: number
  tag?: string
  keywords?: string
}

const getSearchQuery = (
  params: GetSearchQueryParams,
  href?: string | undefined
) => {
  const {
    sort: unsafeSort,
    tags: unsafeTags,
    keywords,
    from: unsafeFrom,
    size: unsafeSize
  } = params

  const query = getUrlQuery(href)

  const sort = unsafeSort || query?.sort || 'all'

  const tags = castArray(unsafeTags || query?.tags || [])

  const searchParams = qs.stringify(
    {
      sort,
      from: unsafeFrom,
      size: unsafeSize,
      tags,
      keywords
    },
    {
      arrayFormat: 'repeat',
      skipNulls: true
    }
  )

  return `/search?${searchParams}`
}

const getUrlQuery = (href?: string) => {
  // INFO: prevent error when running on server side
  // INFO: server side run on node hence window/document is undefined
  if (typeof document === 'undefined') return {}

  if (!href) href = String(document.location)

  const url = new URL(href)
  const query = qs.parse(url.search, {
    ignoreQueryPrefix: true
  })

  return query
}

export { getSearchQuery }
