import { Container, Stack } from '@mui/material'
import { isEmpty } from 'lodash'

import WorkspaceNotFoundDialog from '~ui-components/components/molecules/WorkspaceNotFoundDialog'
import AppLayout from '~ui-components/components/organisms/AppLayout'
import { WorkspaceInfo } from '~ui-components/components/organisms/WorkspacePanels/WorkspaceInfo'
import { WorkspaceTabBar } from '~ui-components/components/organisms/WorkspacePanels/WorkspaceTabBar'
import { WorkspacesTab } from '~ui-components/components/organisms/WorkspacesTab'

import { Header } from '../Header/SignedIn'

const Error = ({ error }) => {
  if (error === 'WorkspaceNotFound') return <WorkspaceNotFoundDialog open />
  return null
}

const defaultSlots = {
  Header,
  WorkspaceInfo,
  TabBar: WorkspaceTabBar,
  Tab: WorkspacesTab
}

const Workspace = (props) => {
  const { error, loading = false, slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  if (!isEmpty(error)) {
    return <Error error={error} />
  }

  return (
    <AppLayout
      gutterTop
      slots={{
        Header: Slots.Header,
        Main: (
          <Container
            maxWidth='xl'
            sx={{ pb: 8 }}
          >
            <Stack spacing={4}>
              <Slots.WorkspaceInfo {...slotProps?.WorkspaceInfo} />
              <Slots.TabBar {...slotProps?.TabBar} />
              <Slots.Tab {...slotProps?.Tab} />
            </Stack>
          </Container>
        )
      }}
    />
  )
}

export { Workspace }
