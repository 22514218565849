import { useRouter } from 'next/router'
import { LoginForm } from 'ui-components'
import { getSignInLink } from 'utils'

import { useAuthContext } from '~user-auth/contexts/auth'
import { useFederatedAuth } from '~user-auth/hooks/useFederatedAuth'

function Login() {
  const router = useRouter()

  const { loading } = useAuthContext()
  const { signIn } = useFederatedAuth()

  const callback = router.query.callback
  const disabled = callback === 'true' || loading

  const handleGoogleMethod = () => {
    signIn({ provider: 'Google' })
  }

  const handleFacebookMethod = () => {
    signIn({ provider: 'Facebook' })
  }

  const handleEmailMethod = () => {
    router.push(getSignInLink('email'))
  }

  return (
    <LoginForm
      slotProps={{
        GoogleButton: {
          disabled,
          onClick: handleGoogleMethod
        },
        FacebookButton: {
          disabled,
          onClick: handleFacebookMethod
        },
        EmailButton: {
          disabled,
          onClick: handleEmailMethod
        }
      }}
    />
  )
}

export { Login }
