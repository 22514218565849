/* eslint-disable @typescript-eslint/ban-ts-comment */
import SvgIcon from '@mui/material/SvgIcon'
import Glass from '@untitled-ui/icons-react/build/esm/Glasses02'
import MapPortal from '@untitled-ui/icons-react/build/esm/Globe02'
import Star from '@untitled-ui/icons-react/build/esm/Star01'
import { useRouter } from 'next/router'
import { NavListProps, NavList as View } from 'ui-components'

export const Community = (props) => {
  const router = useRouter()
  return (
    <View
      label='Community'
      onChange={(value) => router.push(`/${value}`)}
      options={communityOptions}
    />
  )
}

export const communityOptions: NavListProps['options'] = [
  {
    value: 'search',
    label: 'Map portal',
    icon: () => (
      <SvgIcon>
        <MapPortal />
      </SvgIcon>
    )
  },
  {
    value: 'explore',
    label: 'Best of mapstack',
    icon: () => (
      <SvgIcon>
        <Star />
      </SvgIcon>
    )
  }
  // {
  //   value: 'discord',
  //   label: 'Discord server',
  //   icon: () => (
  //     <SvgIcon>
  //       <Glass />
  //     </SvgIcon>
  //   )
  // }
]
