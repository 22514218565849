import {
  Box,
  Skeleton,
  Stack,
  SxProps,
  Typography,
  useTheme
} from '@mui/material'
import { transform } from 'framer-motion'

import Globe05 from '@untitled-ui/icons-react/build/cjs/Globe05'
import { Strong } from '~ui-components/components/atoms/Strong'
import { Chart } from '~ui-components/components/molecules/Chart'

import { ApexOptions } from 'apexcharts'
import { useMemo } from 'react'

type ChartSeries = number[]

interface GeocoderHeaderProps {
  loading?: boolean
  count: number
  total: number
  errors: number
  sx?: SxProps
}

function GeocoderHeader(props: GeocoderHeaderProps) {
  const { sx, loading, count = 0, total = 0, errors = 0 } = props

  const percentage = useMemo(
    () => Math.floor((count / total) * 100) || 0,
    [count, total]
  )

  const [options, series] = useChart(percentage, loading)

  return (
    <Stack
      alignItems='center'
      spacing={2}
      sx={[...(Array.isArray(sx) ? sx : [sx])]}
    >
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
      >
        <Globe05 />
        <Typography
          variant='h5'
          textAlign={'left'}
        >
          Processing address data…
        </Typography>
      </Stack>

      <Box
        sx={{
          height: '240px',
          mt: '-8px !important'
        }}
      >
        <Chart
          width={400}
          height={400}
          options={options}
          series={series}
          type='radialBar'
        />
      </Box>

      {loading && total <= 0 ? (
        <Skeleton
          variant='rounded'
          width='120px'
          sx={{
            fontSize: (theme) => theme.typography.h6.fontSize
          }}
        />
      ) : (
        <Typography
          variant='h6'
          sx={{ mb: 1 }}
        >
          {percentage < 100 ? 'Loading your addresses' : 'Completed!'}
        </Typography>
      )}

      {loading && total <= 0 ? (
        <Skeleton
          variant='rounded'
          width='240px'
          sx={{
            fontSize: (theme) => theme.typography.body2.fontSize
          }}
        />
      ) : (
        <Typography
          color='text.secondary'
          variant='body2'
          textAlign='center'
        >
          Mapstack has successfully mapped{' '}
          <Strong color='inherit'>{count}</Strong> of{' '}
          <Strong color='inherit'>{total}</Strong> addresses, with{' '}
          <Strong color='inherit'>{errors}</Strong> errors
        </Typography>
      )}
    </Stack>
  )
}

function useChart(percentage: number, loading?: boolean) {
  const options = useOptions(percentage, loading)
  const series: ChartSeries = [percentage]
  return [options, series] as const
}

function useOptions(value: number = 0, loading?: boolean): ApexOptions {
  const theme = useTheme()

  const label = `${value}%`
  const color = loading
    ? theme.palette.neutral[200]
    : transform(
        value,
        [96, 100],
        [theme.palette.secondary.main, theme.palette.primary.main]
      )

  return {
    chart: {
      background: 'transparent',
      redrawOnParentResize: false,
      redrawOnWindowResize: false
    },
    colors: [color],
    fill: {
      opacity: 1,
      type: 'solid'
    },
    grid: {
      padding: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0
      }
    },
    labels: [label],
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            color: theme.palette.text.primary,
            fontFamily: "'Plus Jakarta Sans', sans-serif",
            fontSize: '36px',

            fontWeight: 600,
            show: true,
            offsetY: -15
          },
          value: {
            show: false
          }
        },
        endAngle: 90,
        hollow: {
          size: '60%'
        },
        startAngle: -90,
        track: {
          background:
            theme.palette.mode === 'dark'
              ? theme.palette.primary.dark
              : theme.palette.neutral[200],
          strokeWidth: '100%'
        }
      }
    },
    states: {
      active: {
        filter: {
          type: 'none'
        }
      },
      hover: {
        filter: {
          type: 'none'
        }
      }
    },
    stroke: {
      lineCap: 'round'
    },
    theme: {
      mode: theme.palette.mode
    }
  }
}

export { GeocoderHeader }
