import { Divider } from '@mui/material'
import Box from '@mui/material/Box'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import ChevLeft from '@untitled-ui/icons-react/build/esm/ChevronLeftDouble'
import type { FC } from 'react'
import { AppHeaderLogo } from 'ui-components'
import { Scrollbar } from '~ui-components/components/atoms/Scrollbar'
import { UseWidth } from '~ui-components/components/organisms/AppHeader/Anonymous/AppHeaderLayout/Mobile/hooks/useWidth'

interface MobileNavProps extends DrawerProps {
  isEmbed?: boolean
  open?: boolean
  onClose?: () => void
  slots?: Record<string, any>
  sx?: Record<string, any>
}

export const MobileNav: FC<MobileNavProps> = (props) => {
  const {
    isEmbed = false,
    open,
    onClose,
    slots = {},
    PaperProps,
    ...rest
  } = props

  const { sx: paperSxProps } = PaperProps || {}

  const { width, left } = UseWidth(isEmbed)

  return (
    <Drawer
      anchor='left'
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          ...(Array.isArray(paperSxProps) ? paperSxProps : [paperSxProps]),
          width,
          left
        }
      }}
      {...rest}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack
            alignItems='left'
            direction='column'
            spacing={2}
            sx={{ p: 3, flexGrow: 1 }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {slots.Logo ? (
                <slots.Logo />
              ) : (
                <AppHeaderLogo appNavigationLogoHref={'/'} />
              )}
              <Box>
                <IconButton onClick={onClose}>
                  <SvgIcon>
                    <ChevLeft />
                  </SvgIcon>
                </IconButton>
              </Box>
            </Box>
            <Stack
              sx={{ flexGrow: 1 }}
              alignItems='left'
              direction='column'
            >
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <Stack
                  component='ul'
                  spacing={0.5}
                  sx={{
                    flexGrow: 1,
                    listStyle: 'none',
                    m: 0,
                    p: 0
                  }}
                >
                  <slots.Body />
                </Stack>
                <Divider />
                <Stack>
                  <slots.Footer />
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Scrollbar>
    </Drawer>
  )
}
