import { ComponentProps, FC } from 'react'
import { AttributionControl, NavigationControl } from 'react-map-gl'
import MapLibre, { MapEvent, MapRef, ViewState } from 'react-map-gl/maplibre'

import { BASE_MAP_STYLE_URL } from 'map-style-utils'

export type MapProps = Omit<
  MapLibreProps,
  | 'ref'
  | 'reuseMaps'
  | 'interactiveLayerIds'
  // | 'attributionControl'
  | 'pitchWithRotate'
  | keyof ViewState
> & {
  onMapInstance?: (map: MapLibreRef) => void
  fitbounds?: FitBounds
  navigationControl?: boolean
}

export type MapLibreProps = ComponentProps<typeof MapLibre>

export type MapLibreRef = MapRef

export type FitBounds = [number, number, number, number]

export type { MapEvent }

export const Map: FC<MapProps> = (props) => {
  const {
    attributionControl,
    onMapInstance,
    navigationControl = true,
    ...rest
  } = props

  return (
    <MapLibre
      id='default' // DO NOT CHANGE THIS, some useMap hooks rely on this id
      ref={(ref) => {
        if (ref) {
          onMapInstance?.(ref)
        }
      }}
      reuseMaps
      mapStyle={BASE_MAP_STYLE_URL}
      interactiveLayerIds={['default']}
      attributionControl={false}
      dragRotate
      pitchWithRotate
      {...rest}
    >
      {attributionControl && (
        <AttributionControl
          position='bottom-left'
          compact
        />
      )}
      {navigationControl && (
        <NavigationControl
          style={{ top: 0, right: 0, marginRight: 8, marginTop: 8 }}
          showCompass
          visualizePitch
        />
      )}
      {props.children}
    </MapLibre>
  )
}
