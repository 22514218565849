/* eslint-disable @typescript-eslint/ban-ts-comment */
import SvgIcon from '@mui/material/SvgIcon'
import Glass from '@untitled-ui/icons-react/build/esm/Glasses02'
import MapPortal from '@untitled-ui/icons-react/build/esm/Globe02'
import Star from '@untitled-ui/icons-react/build/esm/Star01'
import { useRouter } from 'next/router'
import { Community as View } from 'ui-components'

type CommunityProps = {
  navList?: boolean
}

const Community = (props: CommunityProps) => {
  const { navList = false } = props
  const router = useRouter()
  return (
    <div>
      <View
        navList={navList}
        label='Community'
        onChange={(value) => router.push(`/${value}`)}
        options={communityOptions}
      />
    </div>
  )
}

interface Option {
  label: string
  value: string
  icon: () => JSX.Element
}

export const communityOptions: Option[] = [
  {
    value: 'search',
    label: 'Map portal',
    icon: () => (
      <SvgIcon>
        <MapPortal />
      </SvgIcon>
    )
  },
  {
    value: 'explore',
    label: 'Best of mapstack',
    icon: () => (
      <SvgIcon>
        <Star />
      </SvgIcon>
    )
  }
  // {
  //   value: 'discord',
  //   label: 'Discord server',
  //   icon: () => (
  //     <SvgIcon>
  //       <Glass />
  //     </SvgIcon>
  //   )
  // }
]

export { Community }
