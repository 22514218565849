import { Box, GlobalStyles, Stack } from '@mui/material'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import BrandLogo from '~ui-components/components/atoms/BrandLogo'
import AppFooter from '~ui-components/components/organisms/AppFooter'
const Overrides = (
  <GlobalStyles
    styles={{
      'html, body, #root, #__next': {
        height: '100%'
      }
    }}
  />
)

const WorkspaceCreatorLayout = (props) => {
  const { step } = props
  return (
    <>
      {Overrides}
      <Stack
        height='100%'
        direction='column'
        sx={{
          pt: 'env(safe-area-inset-top)',
          pr: 'env(safe-area-inset-right)',
          pl: 'env(safe-area-inset-left)'
        }}
      >
        <Stack
          direction='row'
          justifyContent='center'
          alignItems='center'
          py={4}
        >
          <NextLink
            href='/'
            passHref
            legacyBehavior
          >
            <Box
              component='a'
              sx={{ all: 'unset', display: 'flex', cursor: 'pointer' }}
            >
              <BrandLogo
                variant='logoCopy'
                size='md'
              />
            </Box>
          </NextLink>
        </Stack>
        <Box
          flex={1}
          flexGrow={1}
          flexShrink={0}
          component='main'
        >
          {step}
        </Box>
        <AppFooter py={4} />
      </Stack>
    </>
  )
}

WorkspaceCreatorLayout.propTypes = {
  step: PropTypes.node
}

export { WorkspaceCreatorLayout }
export default WorkspaceCreatorLayout
