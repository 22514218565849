import { del, get, set } from 'idb-keyval'
import { useEffect } from 'react'

import { useAuthContext, useFederatedAuth } from 'user-auth'

import { LikeButton as Button } from 'ui-components'

import useSocialAction from '~map-viewer/hooks/useSocialAction'
import { useIsMapLiked, useMapLikes } from '~map-viewer/states/like'

export const KEY = 'pre-auth:like'
export const VALUE = 'true'

const LikeButton = (props) => {
  const { authenticated } = useAuthContext()
  const { gotoSignInPage } = useFederatedAuth()

  const liked = useIsMapLiked()
  const likes = useMapLikes()
  const { like, unlike } = useSocialAction()

  useEffect(() => {
    const start = async () => {
      const value = await get(KEY)
      if (value !== VALUE) return

      del(KEY)
      like()
    }

    if (authenticated && !liked) start()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated, liked])

  const handleSignInClick = async () => {
    await set(KEY, VALUE)
    gotoSignInPage()
  }

  return (
    <Button
      {...props}
      authenticated={authenticated}
      likes={likes}
      liked={liked}
      onLike={like}
      onUnlike={unlike}
      onSignInClick={handleSignInClick}
    />
  )
}

export { LikeButton }
export default LikeButton
