import * as qs from 'qs'
import { slugOf } from '~utils/functions/slugOf'

type MapLinkInputProps = {
  mapId: string
  mapName: string
  [key: string]: string
}

const getMapLink = ({ mapId, mapName, ...rest }: MapLinkInputProps): string => {
  const query = qs.stringify(rest)
  return `/map/${mapId}/${slugOf(mapName)}${query ? `?${query}` : ''}`
}

export { getMapLink }
