import { Box, GlobalStyles, Paper, Stack } from '@mui/material'
import PropTypes from 'prop-types'
import { Fragment, useRef } from 'react'
import Layout from '~ui-components/components/organisms/AppLayout'

import FeedSortInput from '~ui-components/components/molecules/FeedSortInput'
import MapGrid from '~ui-components/components/molecules/VirtualizedMapGrid'

const Empty = () => false

const Overrides = (
  <GlobalStyles
    styles={{
      overscrollBehavior: 'none'
    }}
  />
)

const defaultSlots = {
  FeedSortInput,
  MapGrid
}

const MapSearch = (props) => {
  const { slots = {}, slotProps = {} } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const slider = useRef()
  let isDown = false
  let startX = 0
  let scrollLeft = 0

  const handleMouseDown = (event) => {
    isDown = true
    startX = event.pageX - slider.current.offsetLeft
    scrollLeft = slider.current.scrollLeft
  }

  const handleMouseLeave = () => {
    isDown = false
  }

  const handleMouseUp = () => {
    isDown = false
  }

  const handleMouseOver = (event) => {
    if (!isDown) return
    event.preventDefault()
    const x = event.pageX - slider.current.offsetLeft
    const walk = (x - startX) * 3
    const nextScrollLeft = scrollLeft - walk
    slider.current.scrollLeft = nextScrollLeft
  }

  const Main = (
    <Fragment>
      <Stack
        direction='column'
        sx={{
          position: 'relative',
          '&:before': {
            height:
              'calc(var(--app-header-height, 56px) + env(safe-area-inset-top))',
            content: "' '",
            display: 'block'
          }
        }}
      >
        <Box
          component={Paper}
          square
          variant='elevation'
          elevation={0}
          sx={{
            touchAction: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            height: '56px',
            position: 'fixed',
            top: 'calc(70px + env(safe-area-inset-top))',
            left: 0,
            right: 0,
            zIndex: 'feedSelector',
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`
          }}
        >
          <Box
            ref={slider}
            sx={[
              {
                display: 'flex',
                flexDirection: 'row',
                gap: 1,
                whiteSpace: 'nowrap',
                flexWrap: 'nowrap',
                width: '100%',
                height: '100%',
                overflowX: 'scroll',
                // Hide Scroll Bar for Chrome, Safari and Opera
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                // Hide scrollbar for Firefox
                scrollbarWidth: 'none',
                // Hide scrollbar for IE and Edge
                MsOverflowStyle: 'none'
              },
              (theme) => ({
                [theme.breakpoints.down(390)]: {
                  px: 1
                },
                [theme.breakpoints.down(768)]: {
                  px: 2
                },
                [theme.breakpoints.up(768)]: {
                  px: 3
                }
              })
            ]}
            onMouseDown={handleMouseDown}
            onMouseLeave={handleMouseLeave}
            onMouseUp={handleMouseUp}
            onMouseOver={handleMouseOver}
          >
            <Slots.FeedSortInput {...slotProps?.FeedSortInput} />
          </Box>
        </Box>

        <Slots.MapGrid
          sx={{ mt: 2 }}
          {...slotProps?.MapGrid}
        />
      </Stack>
    </Fragment>
  )

  return (
    <>
      {Overrides}
      <Layout
        slots={{
          Header: slots.Header,
          Main,
          Footer: Empty
        }}
      />
    </>
  )
}

MapSearch.propTypes = {
  slots: PropTypes.object,
  slotProps: PropTypes.object
}

export { MapSearch }
export default MapSearch
