/* eslint-disable @typescript-eslint/ban-ts-comment */
import { LoginButton } from 'ui-components'
import { useRouter } from 'next/router'

type LoginProps = {
  navList?: boolean
}

const Login = (props: LoginProps) => {
  const { navList = false } = props
  const router = useRouter()
  return (
    <LoginButton
      navList={navList}
      label='Log in'
      onClick={() => router.push('/sign-in')}
    />
  )
}

export { Login }
