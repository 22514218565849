import type { FC } from 'react'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import { usePopover } from '../PopoverMenu/hooks/usePopover'
import { useMediaQuery, useTheme } from '@mui/material'

interface MultiSelectProps {
  label: string
  // Same as type as the value received above
  onChange?: (value: string) => void
  options: { label: string; value: unknown; icon: () => JSX.Element }[]
}

export const PopoverButton: FC<MultiSelectProps> = (props) => {
  const { label, onChange, options, ...other } = props
  const popover = usePopover<HTMLButtonElement>()
  const [open, setOpen] = useState<boolean>(false)
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  const handleValueChange = useCallback(
    (value: string): void => {
      console.log('event', value)
      onChange?.(value)
      setOpen(false)
    },
    [onChange]
  )

  const handleMouseEnter = useCallback(() => {
    setOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <>
      <Box
        component='li'
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <Button
          disableRipple
          onMouseEnter={handleMouseEnter}
          ref={popover.anchorRef}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'center',
            width: isBrowser ? 'auto' : '100%',
            px: '16px',
            py: '8px',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          }}
        >
          <Typography
            component='span'
            variant='subtitle1'
          >
            {label}
          </Typography>
        </Button>
      </Box>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={handleClose}
        open={open}
        PaperProps={{ style: { width: 250 } }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={(e) => handleValueChange(option.value as string)}
            key={option.label}
          >
            <ListItemIcon>
              <option.icon />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

PopoverButton.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
}
