import { useEffect } from 'react'
import GTM from 'react-gtm-module'

const TAG_MANAGER_ARGS = {
  gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID, // e.g. 'GTM-T2V4CX6',
  events: {
    view_map: 'view map',
    create_map_geojson: 'create_map_geojson',
    create_map_csv: 'create_map_csv',
    delete_map: 'delete map',
    view_workspace: 'view_workspace',
    create_workspace: 'create_workspace',
    sign_in: 'sign_in',
    sign_up: 'sign_up',
    view_profile: 'view_profile'
  }
}

const TagManager = {
  init() {
    GTM.initialize(TAG_MANAGER_ARGS)
  },

  once(cb) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      cb()
    }, [cb])
  },

  dataLayer: {
    createMapAi() {
      GTM.dataLayer({
        dataLayer: {
          event: 'create_map_ai'
        }
      })
    },

    createMapGeojson() {
      GTM.dataLayer({
        dataLayer: {
          event: 'create_map_geojson'
        }
      })
    },

    viewWorkspace() {
      GTM.dataLayer({
        dataLayer: {
          event: 'view_workspace'
        }
      })
    },

    deleteMap() {
      GTM.dataLayer({
        dataLayer: {
          event: 'delete_map'
        }
      })
    },

    viewMap() {
      GTM.dataLayer({
        dataLayer: {
          event: 'view_map'
        }
      })
    },

    signIn() {
      GTM.dataLayer({
        dataLayer: {
          event: 'sign_in'
        }
      })
    },

    signUp() {
      GTM.dataLayer({
        dataLayer: {
          event: 'sign_up'
        }
      })
    },

    viewProfile() {
      GTM.dataLayer({
        dataLayer: {
          event: 'view_profile'
        }
      })
    },

    createWorkspace() {
      GTM.dataLayer({
        dataLayer: {
          event: 'create_workspace'
        }
      })
    }
  }
}

export { TAG_MANAGER_ARGS, TagManager }
