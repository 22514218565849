import type { FC } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { PopoverMenu, NavList } from 'ui-components'
import { useMediaQuery, useTheme } from '@mui/material'

interface Props {
  label: string
  onChange?: (value: string) => void
  options: { label: string; value: string; icon: () => JSX.Element }[]
  navList?: boolean
}

export const Products: FC<Props> = (props) => {
  const { navList = false } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  if (navList) return <NavList {...props} />

  if (isBrowser) return <PopoverMenu {...props} />

  return <NavList {...props} />
}

Products.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
}
