import type { FC } from 'react'
import { useState } from 'react'
import { Search, SearchModal } from 'search-box'
import { Header as View } from 'ui-components'
import { Login } from '../AnonymousHeader/Login'
import { Logo } from '../Logo'
import { Account } from './Account'
import { Community } from './Account/Community'
import { UserProfile } from './Account/UserProfile'
import { Workspace } from './Account/Workspace'
import { Workspaces } from './Account/Workspaces'
import { CreateMap } from './CreateMap'

type HeaderProps = {
  loading?: boolean
  showSearch?: boolean
}

const SignedInHeader: FC<HeaderProps> = (props) => {
  const { showSearch = false, loading = true } = props
  const [openSearchModal, setOpenSearchModal] = useState(false)

  return (
    <View
      showSearch={showSearch}
      loading={loading}
      slots={{
        Logo,
        CreateMap,
        Search,
        Account,
        Workspaces,
        Community,
        Workspace,
        UserProfile,
        Login,
        SearchModal
      }}
      slotProps={{
        searchModal: {
          open: openSearchModal
        }
      }}
      onOpenSearchModal={() => setOpenSearchModal(true)}
      onCloseSearchModal={() => setOpenSearchModal(false)}
    />
  )
}

export { SignedInHeader }
