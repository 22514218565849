import rewind from '@turf/rewind'
import { Feature, FeatureCollection } from 'geojson';
import { cellToBoundary, latLngToCell } from 'h3-js'
import { H3GeojsonOptions, Resolutions } from '../../types'
import { generateResolutions } from '../utils/get-resolutions'
import convex from '@turf/convex'
import { featureCollection, point } from '@turf/helpers';

const defaultOptions: H3GeojsonOptions = {
  size: 'medium',
  aggregateProperties: ['count'],
  aggregateType: 'SUM'
}

function crossesDateLine(polygon) {
  let hasSmall = false;
  let hasBig = false;

  for (const coord of polygon) {
    if (coord[0] > 180 || coord[0] < -180) {
      throw new Error("Longitude values must be between -180 and 180 degrees.");
    }

    if (coord[0] < -170) {
      hasSmall = true;
    } else if (coord[0] > 170) {
      hasBig = true;
    }

    // If both sides are found, the polygon crosses the date line
    if (hasSmall && hasBig) {
      return true;
    }
  }

  return false;
}

function splitPolygonAtDateLine(polygon, props) {
  const yArray: number[] = []
  const left: number[][] = [];
  const right: number[][] = [];

  polygon.forEach(coord => {
    yArray.push(coord[1])
    if (coord[0] < 0) {
      left.push(coord);
    } else {
      right.push(coord);
    }
  });

  let ymin = Math.min(...yArray)
  let ymax = Math.max(...yArray)

  // close off polygons
  left.push([-180, ymin])
  left.push([-180, ymax])

  right.push([180, ymin])
  right.push([180, ymax])


  var leftPoly = convex(featureCollection(left.map(coord => point(coord))))?.geometry.coordinates
  var rightPoly = convex(featureCollection(right.map(coord => point(coord))))?.geometry.coordinates

  console.log('hull is', leftPoly)

  const leftFeat = {
    type: 'Feature',
    properties: props,
    geometry: {
      type: 'Polygon',
      coordinates: leftPoly
    }
  }

  const rightFeat = {
    type: 'Feature',
    properties: props,
    geometry: {
      type: 'Polygon',
      coordinates: rightPoly
    }
  }
  return [leftFeat, rightFeat];
}

function generateH3GeojsonFeaturesFromRecords(
  records: object
): GeoJSON.Feature[] {

  const features: any[] = []
  Object.keys(records).forEach((h3Index) => {
    const boundary: [number, number][] = cellToBoundary(h3Index)

    if (!boundary[0]) {
      throw new Error('unable to generate h3 boundaries')
    }

    const coords = boundary.map((coord) => [coord[1], coord[0]])
    coords.push(coords[0]!)

    const feature = {
      type: 'Feature',
      properties: { ...records[h3Index] },
      geometry: {
        type: 'Polygon',
        coordinates: [coords]
      }
    }
    if (!crossesDateLine(coords)) {
      features.push(rewind(feature as any))
    } else {
      let [left, right] = splitPolygonAtDateLine(coords.slice(0, -1), records[h3Index])
      features.push(left)
      features.push(right)
    }
  })
  return features.filter(feature => feature !== null)
}

function getH3Geojson(
  geojson: FeatureCollection,
  options: H3GeojsonOptions = defaultOptions
): { hexagonGeojson: FeatureCollection; resolution: number } {
  // get resolutions generated from geojson
  const resolutions: Resolutions = generateResolutions(geojson.features)
  const resolution: number = resolutions[options.size]

  // generate records from points
  const points: Feature[] = geojson.features
  const records: Record<string, any> = {}
  let hexBoundaryId: number = 0
  for (const point of points) {
    if (point.geometry.type !== 'Point')
      throw Error("Only 'Point' geometry type is supported")

    const [lng, lat] = point.geometry.coordinates as [number, number]
    const index: string = latLngToCell(lat, lng, resolution)

    if (!records[index]) {
      hexBoundaryId += 1
      records[index] = {
        count: 0,
        hexBoundaryId: hexBoundaryId
      }
      for (let aggProp of options.aggregateProperties) {
        records[index][aggProp] = 0
      }
    }

    records[index].count += 1
    for (let aggProp of options.aggregateProperties) {
      const possValue = point.properties![aggProp] ? parseFloat(`${point.properties![aggProp]}`.replace(/^0+/, '')) : 0
      records[index][aggProp] += possValue
    }
  }

  const hexagonsGeojson: FeatureCollection = {
    type: 'FeatureCollection',
    features: generateH3GeojsonFeaturesFromRecords(records)
  }

  hexagonsGeojson.features.map(feature => feature.id = feature.properties?.hexBoundaryId)

  return {
    hexagonGeojson: hexagonsGeojson,
    resolution: resolutions.medium
  }
}

export { getH3Geojson }
