import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  getCurrentWorkspaceClientCookie,
  setCurrentWorkspaceClientCookie
} from 'utils'
import { useAuthContext } from '~user-auth/contexts/auth'
import { Workspace as WorkspaceType } from '~user-auth/types/__generated/gql/graphql'

interface UseAuthCurrentWorkspaceProps {
  excludeDefaultWorkspace?: boolean
}

type Workspace = Pick<
  WorkspaceType,
  'id' | 'logo' | 'name' | 'description' | 'createdAt' | 'memberCollection'
>
export const useAuthCurrentWorkspace = ({
  excludeDefaultWorkspace = false
}: UseAuthCurrentWorkspaceProps = {}) => {
  const router = useRouter()

  const { user } = useAuthContext()
  const currentWorkspaceId = String(router.query.workspaceId || '')
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace>()

  useEffect(() => {
    const getWorkspaceFromCache = async () => {
      if (currentWorkspaceId) {
        const workspace = user?.membershipCollection?.items
          ?.map((item) => item.workspace)
          .find((w) => w.id === currentWorkspaceId)

        if (workspace) {
          setCurrentWorkspace(workspace as Workspace)
          setCurrentWorkspaceClientCookie({
            id: workspace.id,
            name: workspace.name
          })
        }

        return
      }

      const cachedWorkspace = getCurrentWorkspaceClientCookie()
      if (cachedWorkspace) {
        const workspace = user?.membershipCollection?.items.find(
          (item) => item.workspace.id === cachedWorkspace.id
        )?.workspace

        if (workspace) setCurrentWorkspace(workspace as Workspace)
      } else {
        const workspace = excludeDefaultWorkspace
          ? user?.membershipCollection?.items.find(
              (item) => item.workspace.id !== 'default'
            )?.workspace
          : user?.membershipCollection?.items[0]?.workspace

        if (workspace) {
          setCurrentWorkspace(workspace as Workspace)
          setCurrentWorkspaceClientCookie({
            id: workspace.id,
            name: workspace.name
          })
        }
      }
    }

    getWorkspaceFromCache()
  }, [currentWorkspaceId, user, excludeDefaultWorkspace])

  return currentWorkspace
}
