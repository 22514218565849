import { MapNav as Layout } from 'ui-components'
import { useAuthContext } from 'user-auth'
import { AddMapAction } from '../AddMapAction'
import { MapProfile } from '../MapProfile'

const MapNav = (props) => {
  const { authenticated } = useAuthContext()

  return (
    <Layout
      {...props}
      slots={{
        MapProfile,
        AddMapAction
      }}
      appNavigationLogoHref={authenticated ? '/search' : '/'}
    />
  )
}

export { MapNav }
export default MapNav
