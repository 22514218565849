import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import type { FC, ReactNode } from 'react'
import { getCurrentAuthenticatedUser, getCurrentSession } from 'utils'
import {
  GetUserByIdResults,
  getUserByIdQueryFn
} from '~user-auth/queries/useGetUserById'
import { QueryProvider } from '~user-auth/wrappers/QueryProvider'

export type AuthState = {
  status: 'idle' | 'loading' | 'ready'
  initialized: boolean
  loading: boolean
  authenticated: boolean
  ready: boolean
  userId?: string
  user: GetUserByIdResults | null
  token: string | null
  isStaff: boolean
}

export const initialState: AuthState = {
  status: 'idle',
  initialized: false,
  loading: false,
  authenticated: false,
  ready: false,
  user: null,
  token: null,
  isStaff: false
}

export type AuthContext = AuthState & {
  handleUpdate: (auth: Partial<AuthState>) => void
  handleReset: () => void
}

export const AuthContext = createContext<AuthContext>({
  ...initialState,
  handleUpdate: () => {},
  handleReset: () => {}
})

export const AuthProvider: FC<{
  children: ReactNode
}> = ({ children }) => {
  const [state, setState] = useState<AuthState>(initialState)

  useEffect(() => {
    const start = async () => {
      handleUpdate({ status: 'loading', loading: true, ready: false })

      const cognitoUser = await getCurrentAuthenticatedUser().catch(() => null)
      const cognitoSession = await getCurrentSession().catch(() => null)

      const user = cognitoUser
        ? await getUserByIdQueryFn(
            cognitoUser.signInUserSession.idToken.payload['custom:id']
          )
        : null

      if (user && cognitoSession) {
        handleUpdate({
          userId: user.id,
          status: 'ready',
          authenticated: true,
          initialized: true,
          loading: false,
          ready: true,
          user,
          token: cognitoSession.getIdToken().getJwtToken(),
          isStaff:
            !!user.email &&
            ['@mangomap.com', '@mapstack.io'].some((domain) =>
              user.email.includes(domain)
            )
        })

        return
      }

      handleUpdate({
        status: 'ready',
        authenticated: false,
        initialized: true,
        ready: true,
        loading: false,
        isStaff: false
      })
    }

    start()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUpdate = useCallback<AuthContext['handleUpdate']>((auth) => {
    setState((prevState) => {
      return { ...prevState, ...auth }
    })
  }, [])

  const handleReset = useCallback<AuthContext['handleReset']>(() => {
    setState(initialState)
  }, [])

  return (
    <QueryProvider>
      <AuthContext.Provider value={{ ...state, handleUpdate, handleReset }}>
        {children}
      </AuthContext.Provider>
    </QueryProvider>
  )
}

export const AuthConsumer = AuthContext.Consumer

export const useAuthContext = () => useContext(AuthContext)
