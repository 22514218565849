import { Box, Divider, SvgIcon, Tab, Tabs } from '@mui/material'
import Grid01 from '@untitled-ui/icons-react/build/esm/Grid01'
import Map01 from '@untitled-ui/icons-react/build/esm/Map01'
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01'

import { Skeleton } from '~ui-components/components/atoms/Skeleton'

export enum USER_PROFILE_TAB {
  MAPS = 'maps',
  WORKSPACES = 'workspaces',
  SETTINGS = 'settings'
}

const UserProfileTabBar = (props) => {
  const {
    canUpdateUserSettings = false,
    totalWorkspaces = 0,
    totalMaps = 0,
    loading = false,
    currentTab = USER_PROFILE_TAB.MAPS,
    onTabChange
  } = props

  const tabs = [
    {
      id: 'UserProfileMapsTab',
      'data-testid': 'MapsTab',
      label: totalMaps ? `Maps (${totalMaps})` : 'Maps',
      value: USER_PROFILE_TAB.MAPS,
      icon: (
        <SvgIcon>
          <Map01 />
        </SvgIcon>
      ),
      iconPosition: 'start'
    },
    {
      id: 'UserProfileWorkspacesTab',
      'data-testid': 'WorkspacesTab',
      label: totalWorkspaces ? `Workspaces (${totalWorkspaces})` : 'Workspaces',
      value: USER_PROFILE_TAB.WORKSPACES,
      icon: (
        <SvgIcon>
          <Grid01 />
        </SvgIcon>
      ),
      iconPosition: 'start'
    }
  ]

  if (canUpdateUserSettings) {
    tabs.push({
      id: 'UserProfileSettingsTab',
      'data-testid': 'SettingsTab',
      label: 'Settings',
      value: USER_PROFILE_TAB.SETTINGS,
      icon: (
        <SvgIcon>
          <Settings01 />
        </SvgIcon>
      ),
      iconPosition: 'start'
    })
  }

  return loading ? (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='nowrap'
      gap={1}
      sx={{
        overflowX: 'hidden'
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <Skeleton
            loading
            variant='square'
            key={index}
            width={122}
            height={40}
          />
        )
      })}
    </Box>
  ) : (
    <Box>
      <Tabs
        data-testid='UserProfileTabBar'
        indicatorColor='primary'
        onChange={onTabChange}
        scrollButtons='auto'
        sx={{ px: 3 }}
        textColor='primary'
        value={currentTab}
        variant='scrollable'
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}

export { UserProfileTabBar }
