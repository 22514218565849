import { withSSRContext } from 'aws-amplify'
import {
  getRemixedMapIdFromRequestCookies,
  isRedirectedFromPointsMapEditor,
  isRedirectedFromPolygonsMapEditor
} from 'utils'
import { getMapById } from '~map-viewer/api/getMapById'
import { generateOpenGraph } from '../generateOpenGraph'

const getServerSideProps = async (context) => {
  const { params, req, query } = context || {}
  const { mapId, slug, hashed } = params || {}
  const { referer, host } = req?.headers || {}
  const { embedKey } = query

  if (!mapId) return { notFound: true }

  let map: Record<string, any> | null = null
  const ssrContext = withSSRContext({ req })

  try {
    map = await getMapById(mapId, ssrContext, embedKey)
  } catch (error) {
    console.warn(error)
  }

  const isRemixedMap = mapId === getRemixedMapIdFromRequestCookies(req.cookies)
  const isRedirectFromPolygonsMapEditor = isRedirectedFromPolygonsMapEditor(
    map,
    referer
  )
  const isRedirectFromPointsMapEditor = isRedirectedFromPointsMapEditor(referer)

  // point map will always have been saved before redirected
  if (
    !isRemixedMap &&
    !isRedirectFromPolygonsMapEditor &&
    !isRedirectFromPointsMapEditor &&
    !map
  ) {
    return { notFound: true }
  }

  const og = generateOpenGraph(host, map, slug as string[])

  const shareState = hashed
    ? JSON.parse(Buffer.from(hashed as string, 'base64').toString('utf-8'))
    : {}

  return {
    props: {
      mapId,
      shareState,
      og,
      map: isRedirectFromPolygonsMapEditor ? null : map,
      slug: slug ?? null,
      embedKey: embedKey ?? null
    }
  }
}

export { getServerSideProps }
