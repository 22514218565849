/* eslint-disable @typescript-eslint/ban-ts-comment */
import SvgIcon from '@mui/material/SvgIcon'
import Maps from '@untitled-ui/icons-react/build/esm/Map01'
import Settings from '@untitled-ui/icons-react/build/esm/Settings01'
import Users from '@untitled-ui/icons-react/build/esm/Users01'
import { useRouter } from 'next/router'
import { NavListProps, NavList as View } from 'ui-components'
import { useAuthCurrentWorkspace } from 'user-auth'

export const Workspace = (props) => {
  const router = useRouter()
  const currentWorkspace = useAuthCurrentWorkspace()

  if (!currentWorkspace) return null

  return (
    <View
      label='Workspace'
      onChange={(value) => {
        router.push(
          `/workspace/${currentWorkspace.id}/${currentWorkspace.name}?tab=${value}`
        )
      }}
      options={workspaceOptions}
    />
  )
}

export const workspaceOptions: NavListProps['options'] = [
  {
    value: 'maps',
    label: 'Maps',
    icon: () => (
      <SvgIcon>
        <Maps />
      </SvgIcon>
    )
  },
  {
    value: 'members',
    label: 'Members',
    icon: () => (
      <SvgIcon>
        <Users />
      </SvgIcon>
    )
  },
  {
    value: 'settings',
    label: 'Settings',
    icon: () => (
      <SvgIcon>
        <Settings />
      </SvgIcon>
    )
  }
]
