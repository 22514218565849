import { useRouter } from 'next/router'
import { FC, useMemo, useState } from 'react'
import { SearchModal as Component } from 'ui-components'
import { getSearchQuery, throttle } from 'utils'
import {
  SearchMapsVariables,
  useSearchMaps
} from '~search-box/queries/useSearchMaps'
import { Feed_Types } from '~search-box/types/__generated/gql/graphql'

import { SIZE } from '~search-box/constants/search'

type SearchModalProps = {
  onClose: () => void
}

export const SearchModal: FC<SearchModalProps> = ({ onClose }) => {
  const router = useRouter()

  const [searchVariables, setSearchVariables] = useState<SearchMapsVariables>({
    query: '',
    from: 0, // there's no pagination in the header search
    size: SIZE,
    feedType: Feed_Types.All
  })
  const { refetch, data } = useSearchMaps(searchVariables)
  const results = useMemo(() => data?.items || [], [data])

  const gotoSearchResults = () => {
    const { query: keywords } = searchVariables
    if (!keywords) return
    onClose()
    router.push(getSearchQuery({ keywords: keywords }))
  }

  const handleChange = (value) => {
    setSearchVariables((prev) => ({ ...prev, query: value }))
    throttle(refetch, 200)
  }

  const handleSubmit = () => gotoSearchResults()

  return (
    <Component
      results={results}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClose={onClose}
    />
  )
}
