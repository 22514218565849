/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Box, Paper, Stack } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import SvgIcon from '@mui/material/SvgIcon'
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01'
import PropTypes from 'prop-types'
import Skeleton from '~ui-components/components/atoms/Skeleton'
import { MobileNav } from './Drawer'
import { useMobileNav } from './hooks/useMobileNav'

const defaultSlots = {
  Products: () => null,
  Logo: () => null,
  Search: () => null,
  Account: () => null,
  SearchModal: () => null
}

const Layout = (props) => {
  const {
    loading = true,
    showSearch = false,
    slots = {},
    slotProps = {},
    onOpenSearchModal = () => {},
    onCloseSearchModal = () => {}
  } = props

  const Slots = {
    ...defaultSlots,
    ...slots
  }

  const mobileNav = useMobileNav()

  return (
    <div>
      {slotProps.searchModal?.open && (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: 999999,
            top: 0
          }}
        >
          {
            <Slots.SearchModal
              onClose={onCloseSearchModal}
              {...slotProps.searchModal}
            />
          }
        </div>
      )}
      <Paper
        variant='outlined'
        id='Header'
        component={Stack}
        sx={(theme) => ({
          width: '100%',
          height:
            'calc(var(--app-header-height, 70px) + env(safe-area-inset-top))',

          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 'header',

          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',

          pt: 'env(safe-area-inset-top)',
          borderTop: 'none',
          borderLeft: 'none',
          borderRight: 'none',
          borderRadius: 0,

          [theme.breakpoints.down(390)]: {
            px: 1
          },
          [theme.breakpoints.down(768)]: {
            px: 2
          },
          [theme.breakpoints.up(768)]: {
            px: 3
          }
        })}
      >
        <Box
          sx={styles.start}
          className='start'
        >
          <Slots.Logo {...slotProps?.Logo} />
        </Box>
        {props.showSearch && (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              gap: 2,
              justifyContent: 'center',
              alignItems: 'center',
              mx: 2
            }}
          >
            <Box
              sx={{
                justifyContent: 'center'
              }}
            >
              <Slots.Search
                onOpen={onOpenSearchModal}
                {...slotProps?.Search}
              />
            </Box>
          </Box>
        )}

        <Box
          className='end'
          sx={styles.end}
        >
          {loading ? (
            <Skeleton
              loading={loading}
              width={40}
              height={40}
              variant='circular'
              sx={{ p: '4px' }}
            ></Skeleton>
          ) : (
            <Box>
              <IconButton onClick={mobileNav.handleOpen}>
                <SvgIcon>
                  <Menu01Icon />
                </SvgIcon>
              </IconButton>
              <MobileNav
                open={mobileNav.open}
                onClose={mobileNav.handleClose}
                slots={{
                  ...slots
                }}
              />
            </Box>
          )}
        </Box>
      </Paper>
    </div>
  )
}

const styles = {
  start: () => ({
    display: 'flex'
  }),
  end: () => ({
    display: 'flex'
  })
}

const AnonymousMobileAppHeaderLayout = (props) => {
  return <Layout {...props} />
}

const AnonymousMobileAppHeaderLayoutPropTypes = {
  loading: PropTypes.bool,
  showSearch: PropTypes.bool,
  onOpenSearchModal: PropTypes.func,
  onCloseSearchModal: PropTypes.func,
  slots: PropTypes.object,
  slotProps: PropTypes.object
}

AnonymousMobileAppHeaderLayout.propTypes =
  AnonymousMobileAppHeaderLayoutPropTypes

export {
  AnonymousMobileAppHeaderLayout,
  AnonymousMobileAppHeaderLayoutPropTypes
}
