import { BottomNavigation, Box, GlobalStyles } from '@mui/material'
import Fab from '@mui/material/Fab'
import { FC } from 'react'

import { MapBarProps } from '..'

export const MobileMapBar: FC<MapBarProps> = ({
  slots: Slots,
  authenticated
}) => {
  return (
    <>
      <GlobalStyles
        styles={{
          ':root': {
            '--map-bar-height': '64px',
            '--sidebar-offset-bottom': '64px'
          }
        }}
      />

      {!authenticated && (
        <Box sx={{ top: 20, left: 20, position: 'absolute', zIndex: 3 }}>
          <Fab
            sx={{
              zIndex: 0,
              boxShadow: (theme) => theme.shadows[1],
              backgroundColor: (theme) => theme.palette.common.white
            }}
          >
            <Slots.NavButton />
          </Fab>
        </Box>
      )}

      <BottomNavigation
        sx={{
          height: 'var(--map-bar-height)',
          '& li,span': { flex: 1, maxWidth: 'unset' }
        }}
      >
        <Slots.MapSearchButton />
        <Slots.MapFilterButton />
        <Slots.MapDetailsButton />
        <Slots.MapShareButton />
      </BottomNavigation>
    </>
  )
}
