import { graphierQL, isAuthenticated } from 'utils'
import {
  GetMapByIdQuery,
  GetMapByIdQueryVariables
} from '~map-creator/types/__generated/gql/graphql'

const getMapById = async (mapId) => {
  const bool = await isAuthenticated()
  const data = await graphierQL<GetMapByIdQuery, GetMapByIdQueryVariables>({
    useToken: bool,
    query: getMapByIdQuery,
    variables: { id: mapId }
  })

  return data.getMapById
}

const getMapByIdQuery = /* GraphQL */ `
  query GetMapById($id: ID!) {
    getMapById(id: $id) {
      id
      createdAt
      dataId
      dataReady
      name
      bbox
      centroid
      properties {
        name
        type
        visualisation
        isAggregate
        isPercentage
        isDefaultVisualisation
        isTitle
        classBreaks
      }
      workspace {
        logo
        inviteId
        id
        description
        createdAt
        name
        memberCollection {
          total
          items {
            user {
              id
              firstName
              lastName
              avatar
            }
          }
        }
      }
      creator {
        id
        firstName
        lastName
        email
        avatar
        createdAt
      }
      mapTagCollection {
        total
        items {
          tag {
            id
            type
            name
            description
            count
          }
        }
      }
      license {
        name
        url
      }
      source {
        name
        url
      }
    }
  }
`

export { getMapById, getMapByIdQuery }
