import Box from '@mui/material/Box'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useCallback } from 'react'

export interface NavListProps {
  label: string
  // Same as type as the value received above
  onChange?: (value: string) => void
  options: { label: string; value: unknown; icon: () => JSX.Element }[]
}

export const NavList: FC<NavListProps> = (props) => {
  const { label, onChange, options, ...other } = props
  const handleValueChange = useCallback(
    (value: string): void => {
      onChange?.(value)
    },
    [onChange]
  )

  return (
    <>
      <Box component='div'>
        <Typography
          component='p'
          variant='subtitle1'
        >
          {label}
        </Typography>
      </Box>
      <Box component='div'>
        {options.map((option) => (
          <MenuItem
            onClick={(e) => handleValueChange(option.value as string)}
            key={option.label}
          >
            <ListItemIcon sx={{ mr: 0 }}>
              <option.icon />
            </ListItemIcon>
            <ListItemText>{option.label}</ListItemText>
          </MenuItem>
        ))}
      </Box>
    </>
  )
}

NavList.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
}
