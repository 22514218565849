import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import { AppHeaderSearch as Component } from 'ui-components'
import { getSearchQuery, throttle } from 'utils'
import {
  SearchMapsVariables,
  useSearchMaps
} from '~header/queries/useSearchMaps'

import { SIZE } from '~header/constants/search'
import { Feed_Types } from '~header/types/__generated/gql/graphql'

const Search = ({ onOpen }) => {
  const router = useRouter()

  const [searchVariables, setSearchVariables] = useState<SearchMapsVariables>({
    query: '',
    from: 0, // there's no pagination in the header search
    size: SIZE,
    feedType: Feed_Types.All
  })
  const { refetch, data } = useSearchMaps(searchVariables)
  const results = useMemo(() => data?.items || [], [data])

  const gotoSearchResults = () => {
    const { query: keywords } = searchVariables

    if (!keywords) return

    router.push(getSearchQuery({ keywords: keywords }))
  }

  const handleChange = (value) => {
    setSearchVariables((prev) => ({ ...prev, query: value }))
    throttle(refetch, 200)
  }

  const handleSearchClick = () => gotoSearchResults()

  const handleSubmit = () => gotoSearchResults()

  return (
    <Component
      onOpen={onOpen}
      value={searchVariables.query}
      results={results}
      onChange={handleChange}
      onSearchClick={handleSearchClick}
      onSubmit={handleSubmit}
    />
  )
}

export { Search }
