import { Divider, useMediaQuery, useTheme } from '@mui/material'
import type { FC } from 'react'
import { AnonymousMobileAppHeaderLayout as MobileLayout } from 'ui-components'
import { AppHeaderLayout as Layout } from '~ui-components/components/organisms/AppHeader/AppHeaderLayout'

type HeaderProps = {
  loading?: boolean
  showSearch?: boolean
  slots?: any
  slotProps?: any
  onOpenSearchModal?: () => void
  onCloseSearchModal?: () => void
}

const Header: FC<HeaderProps> = (props) => {
  const {
    loading = true,
    showSearch = false,
    slots = {},
    slotProps,
    onOpenSearchModal = () => {},
    onCloseSearchModal = () => {}
  } = props

  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  if (isBrowser) {
    return (
      <Layout
        loading={loading}
        showSearch={showSearch}
        onOpenSearchModal={onOpenSearchModal}
        onCloseSearchModal={onCloseSearchModal}
        slots={{
          ...slots
        }}
        slotProps={slotProps}
      />
    )
  } else
    return (
      <MobileLayout
        loading={loading}
        showSearch={showSearch}
        slots={{
          Body: () => (
            <>
              <slots.Workspaces />
              <Divider />
              <slots.Workspace />
              <Divider />
              <slots.Community />
              <Divider />
              <slots.UserProfile />
            </>
          ),
          Footer: () => (
            <>
              <Divider />
              <slots.CreateMap />
              <slots.Login />
            </>
          ),
          ...slots
        }}
        slotProps={slotProps}
        onOpenSearchModal={onOpenSearchModal}
        onCloseSearchModal={onCloseSearchModal}
      />
    )
}

export { Header }
