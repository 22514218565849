import * as qs from 'qs'
import { slugOf } from '~utils/functions/slugOf'

const getEditMapLink = ({ workspaceId, workspaceName, mapId }) => {
  const query = qs.stringify({ action: 'edit' })
  // prettier-ignore
  return `/workspace/${workspaceId}/${slugOf(workspaceName)}/map/${mapId}?${query}`
}

export { getEditMapLink }
