import {
  AnonymousAppHeaderLayout as Layout,
  AnonymousMobileAppHeaderLayout as MobileLayout
} from 'ui-components'

import { useMediaQuery, useTheme } from '@mui/material'

const AnonymouseHeader = (props) => {
  const {
    slots = {},
    loading = true,
    slotProps,
    showSearch = false,
    onOpenSearchModal = () => {},
    onCloseSearchModal = () => {}
  } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  if (isBrowser)
    return (
      <Layout
        loading={loading}
        showSearch={showSearch}
        onOpenSearchModal={onOpenSearchModal}
        onCloseSearchModal={onCloseSearchModal}
        slots={{
          ...slots
        }}
        slotProps={slotProps}
      />
    )
  else {
    return (
      <MobileLayout
        loading={loading}
        showSearch={showSearch}
        slots={{
          Body: () => (
            <>
              {/* <slots.Products /> */}
              <slots.Community />
            </>
          ),
          Footer: () => (
            <>
              <slots.CreateMap />
              <slots.Login />
            </>
          ),
          ...slots
        }}
        slotProps={slotProps}
        onOpenSearchModal={onOpenSearchModal}
        onCloseSearchModal={onCloseSearchModal}
      />
    )
  }
}

export { AnonymouseHeader }
export default AnonymouseHeader
