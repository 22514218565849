import type { FC } from 'react'
import { useState } from 'react'
import { AnonymouseHeader as Component } from 'ui-components'
import { Logo } from '../Logo'
import { Search } from '../Search'
import { SearchModal } from '../Search/SearchModal'
import { CreateMap } from '../SignedInHeader/CreateMap'
import { Community } from './Community'
import { Login } from './Login'

type Props = {
  loading?: boolean
  showSearch?: boolean
}
const AnonymousHeader: FC<Props> = (props) => {
  const { showSearch = false, loading = true } = props
  const [openSearchModal, setOpenSearchModal] = useState(false)
  return (
    <Component
      loading={loading}
      showSearch={showSearch}
      slots={{
        Community,
        CreateMap,
        Login,
        Logo,
        Search,
        SearchModal
      }}
      slotProps={{
        searchModal: {
          open: openSearchModal
        }
      }}
      onOpenSearchModal={() => setOpenSearchModal(true)}
      onCloseSearchModal={() => setOpenSearchModal(false)}
    />
  )
}

export { AnonymousHeader }
