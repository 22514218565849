import { Skeleton, Stack, SxProps } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'

import { GeocoderItem } from '~ui-components/components/molecules/GeocoderItem'

type Item = {
  id: string
  name: string
}

interface GeocoderListProps extends React.ComponentProps<typeof Stack> {
  sx?: SxProps
  initial?: boolean
  fadeTop?: boolean
  fadeBottom?: boolean

  loading?: boolean
  next: Item[]
  total: number
  delay?: number
}

function GeocoderList(props: GeocoderListProps) {
  const {
    sx,
    initial = true,
    fadeTop,
    fadeBottom = true,
    next: nextProp = [],
    total: totalProp,
    delay = 100
  } = props

  const parentRef = useRef(null)

  const itemsRef = useRef<Item[]>([])
  const [currentItems, setCurrentItems] = useState<Item[]>([])

  const itemsVirtualizer = useVirtualizer({
    count: totalProp,
    getScrollElement: () => parentRef.current,
    estimateSize: () => totalProp,
    overscan: 10
  })

  const virtualItems = itemsVirtualizer.getVirtualItems()

  const start = useRef(false)
  const total = useRef(0)
  total.current = totalProp

  useEffect(() => {
    itemsRef.current.push(...nextProp)
    start.current = nextProp.length > 0
  }, [nextProp])

  const once = useRef(false)
  const index = useRef(0)
  const interval = useRef<any>()

  useEffect(() => {
    if (!start.current) return
    if (once.current) return

    const runner = () => {
      if (interval.current) clearInterval(interval.current)
      interval.current = setInterval(() => {
        if (index.current >= total.current) {
          clearInterval(interval.current)
          return
        }

        const item = itemsRef.current[index.current]

        if (!item) return

        setCurrentItems((previous) => [item, ...previous])
        index.current += 1
      }, delay)
    }

    runner()
    once.current = true
  })

  useEffect(() => {
    return () => {
      start.current = false
      once.current = false
      index.current = 0
      total.current = 0
      interval.current = undefined
      setCurrentItems([])
      clearInterval(interval.current)
    }
  }, [])

  const loading = currentItems.length <= 0

  return (
    <>
      {loading ? (
        <Stack
          spacing={3}
          sx={[{ minWidth: '400px' }, ...(Array.isArray(sx) ? sx : [sx])]}
        >
          {Array.from({ length: 10 }).map((_, index) => (
            <Skeleton
              key={`Skeleon-${index}`}
              width='100%'
              height='64px'
              variant='rectangular'
              sx={{
                borderRadius: '20px'
              }}
            />
          ))}
        </Stack>
      ) : (
        <Stack
          ref={parentRef}
          sx={[
            (theme) => ({
              '--fade-background-color': theme.palette.background.default
            }),
            {
              position: 'relative',
              height: '100%',
              minHeight: '120px',
              minWidth: '400px',
              listStyle: 'none',
              overflow: 'hidden',
              p: 0,
              m: 0
            },
            fadeTop && {
              '&:before': {
                content: '""',
                position: 'absolute',
                zIndex: 1,
                top: 0,
                left: 0,
                width: '100%',
                height: '64px',
                background:
                  'linear-gradient(to top, transparent 0%, var(--fade-background-color, white) 80% , var(--fade-background-color, white) 100%)'
              }
            },
            fadeBottom && {
              '&:after': {
                content: '""',
                position: 'absolute',
                zIndex: 1,
                bottom: 0,
                left: 0,
                width: '100%',
                height: '64px',
                background:
                  'linear-gradient(to bottom, transparent 0%, var(--fade-background-color, white) 80% , var(--fade-background-color, white) 100%)'
              }
            },
            ...(Array.isArray(sx) ? sx : [sx])
          ]}
        >
          <Stack
            spacing={3}
            direction='column'
            justifyContent='flex-start'
            sx={{
              height: `${itemsVirtualizer.getTotalSize()}px`,
              width: '100%',
              position: 'relative'
            }}
          >
            <AnimatePresence
              mode='popLayout'
              initial={initial}
            >
              {virtualItems.map((virtualItem) => {
                const { index } = virtualItem

                const item = currentItems[index]

                if (!item) return null

                return (
                  <motion.div
                    layout
                    key={item.id}
                    layoutId={item.id}
                    initial={{ opacity: 0, scale: 0.77 }}
                    animate={{
                      opacity: 1,
                      scale: 1
                    }}
                    exit={{ y: 100 }}
                    transition={{
                      duration: 0.1
                    }}
                    style={{ position: 'relative' }}
                  >
                    <GeocoderItem
                      status='success'
                      primaryText={item.name}
                    />
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </Stack>
        </Stack>
      )}
    </>
  )
}

export { GeocoderList }
