import { useAuthContext } from 'user-auth'
import { useWorkspaceId } from '../useWorkspaceId'

export const useIsPartOfWorkspace = () => {
  const workspaceId = useWorkspaceId()
  const { user } = useAuthContext()

  if (!workspaceId || !user) return false

  const membership = user.membershipCollection.items.find(
    (membership) => membership.workspace.id === workspaceId
  )
  return !!membership
}
