import { App as Drawer } from 'map-create-drawer'
import { useState } from 'react'
import { MapCreateButton } from 'ui-components'

function CreateMap() {
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <MapCreateButton onClick={handleClick}>Create Map</MapCreateButton>

      <Drawer
        open={open}
        onClose={handleClose}
      />
    </>
  )
}

export { CreateMap }
