import { useState } from 'react'

import AvatarButton from '~ui-components/components/molecules/AvatarButton'
import SignInButton from '~ui-components/components/molecules/SignInButton'
import { AccountButton } from '..'

const MapProfile = ({
  isLoading = false,
  variant = 'desktop',
  authenticated,
  user,
  workspaces,
  onSignInClick,
  onSignOutClick
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handleAccountMenuOpen = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = (event) => {
    event.preventDefault()
    setAnchorEl(null)
  }

  const handleSignOutClick = () => {
    setAnchorEl(null)
    onSignOutClick()
  }

  return (
    <>
      {authenticated ? (
        <AccountButton
          user={user}
          workspaces={workspaces}
          onLogout={handleSignOutClick}
        />
      ) : (
        <>
          {variant !== 'mobile' ? (
            <SignInButton
              variant='contained'
              onClick={onSignInClick}
            />
          ) : (
            <AvatarButton
              tooltip={{
                title: 'Sign In'
              }}
              onClick={onSignInClick}
            />
          )}
        </>
      )}
    </>
  )
}

export { MapProfile }
