import { MS_DEFAULT_HEIGHT, MS_MAX_HEIGHT_FORMULA, MS_MIN_HEIGHT } from '../constants/index';


// currenlty using linear graph (y=mx+c) probaly worth exploring non-linear graphs later down the line
function generateMaxHeight(resolution: number): number {
  const HEIGHT_LINEAR_MODIFIER = 0.4
  const { M, C } = MS_MAX_HEIGHT_FORMULA
  if (resolution < 8) return HEIGHT_LINEAR_MODIFIER * M * resolution + C
  return HEIGHT_LINEAR_MODIFIER * ((M * 8) + C)
}

function generateHeights(min: number, max: number, n: number): number[] {
  if (n < 1) {
    throw new Error('Number of parts n must be at least 1.')
  }
  if (min >= max) {
    throw new Error('Minimum value must be less than the maximum value.')
  }

  const range: number[] = []
  let stepSize = (max - min) / n
  for (let i = 0; i <= n; i++) {
    range.push(min + (i * stepSize))
  }

  // last height value was always a bit weird, just a visual fix
  if (range.length > 2) {
    range[0] = 0.55 * range[1]!
  }

  return range
}

function generateHeightRamp(dataClassBreaks, resolution) {
  const msHeights = generateHeights(
    MS_MIN_HEIGHT,
    generateMaxHeight(resolution),
    dataClassBreaks.length
  )
  const heightRamp: any = []
  for (let i = 0; i < dataClassBreaks.length; i++) {
    if (dataClassBreaks[i].length !== 0) { // workaround for non-continous data having class breaks on consecutive values e.g ints w/ class breaks 0,2,3,4,7,9
      heightRamp.push(dataClassBreaks[i])
      heightRamp.push(msHeights[i])
    }
  }
  heightRamp.push(MS_DEFAULT_HEIGHT)
  return heightRamp
}

export { generateHeightRamp, generateHeights, generateMaxHeight }
