import { NextPage } from 'next'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'

import { Amplify } from 'aws-amplify'
import { useLoadingIndicator } from 'promise-tracker'
import { Fragment } from 'react'
import Analytics from 'react-ga4'

import { MapViewerProvider } from 'map-viewer'
import { AppErrorDialog, ErrorBoundary, UIProvider } from 'ui-components'
import { AuthProvider } from 'user-auth'
import { ReactDevTools, TagManager } from 'utils'

import 'overlayscrollbars/overlayscrollbars.css'
import 'promise-tracker/src/assets/styles/nprogress.css'
import 'simplebar-react/dist/simplebar.min.css'
import './index.css'

const host =
  typeof window !== 'undefined' && window.location.origin
    ? window.location.origin
    : ''

const authConfig = {
  region: process.env.REGION,
  userPoolId: process.env.USER_POOL_ID,
  userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
  oauth: {
    domain:
      process.env.DOMAIN_NAME === 'mapstack.io'
        ? 'auth.mapstack.io'
        : `auth-${process.env.DOMAIN_NAME}`,
    scope: ['email', 'profile', 'openid'],
    responseType: 'code',
    redirectSignIn: `${host}/sign-in/callback`,
    redirectSignOut: `${host}/sign-out`
  }
}

Amplify.configure({
  API: {
    aws_appsync_graphqlEndpoint: process.env.GRAPHQL_APPSYNC_API_URL,
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: process.env.GRAPHQL_APPSYNC_API_KEY
  },
  Auth: authConfig,
  ssr: true
})

/**
 * Setup Google Analytic 4
 * - To capture pageview
 */
Analytics.initialize(process.env.GA_MEASUREMENT_ID!)

/**
 * Enable or disable React Dev Tools
 * based on NODE_ENV or ENABLE_REACT_DEV_TOOLS
 */
ReactDevTools.init()

/**
 * :note: to be removed when we decided to get ride of map-viewer
 * This code removes the overflow property from the body element when a route change occurs
 * because the body-scroll-lock library is unable to remove it.
 */
Router.events.on('routeChangeComplete', () => {
  document.body.style.removeProperty('overflow')
})

interface CustomAppProps extends AppProps {
  Component: NextPage
}

const App = ({ Component, pageProps }: CustomAppProps) => {
  useLoadingIndicator()

  TagManager.once(TagManager.init)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <Fragment>
      <Head key='_app'>
        <meta
          name='viewport'
          content='initial-scale=1, viewport-fit=cover, width=device-width, user-scalable=no'
        />
        <meta
          name='apple-mobile-web-app-capable'
          content='yes'
        />
        <meta
          name='apple-mobile-web-app-status-bar-style'
          content='black-translucent'
        />
      </Head>
      <UIProvider>
        <ErrorBoundary fallback={Fallback}>
          <AuthProvider>
            {/* TODO: When we remove map-viewer package, please remove this wrapper too */}
            <MapViewerProvider>
              {getLayout(<Component {...pageProps} />)}
            </MapViewerProvider>
          </AuthProvider>
        </ErrorBoundary>
      </UIProvider>
    </Fragment>
  )
}

function Fallback() {
  return <AppErrorDialog open />
}

export default App
