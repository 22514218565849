import { Pagination as MuiPagination } from '@mui/material'
import { useMemo } from 'react'
import { getPaginationCount } from '~ui-components/utilities/getPaginationCount'
import { getPaginationPage } from '~ui-components/utilities/getPaginationPage'

interface PaginationProps {
  page: number | string
  total: number
  limit: number
  onChange: React.ComponentProps<typeof MuiPagination>['onChange']
}

const Pagination = (props: PaginationProps) => {
  const { page: pageProp, total, limit, onChange, ...rest } = props

  const count = useMemo(() => getPaginationCount(total, limit), [total, limit])
  const page = useMemo(() => getPaginationPage(pageProp), [pageProp])

  return (
    <MuiPagination
      count={count}
      page={page}
      onChange={onChange}
      {...rest}
    />
  )
}

export { Pagination }

