import type { FC } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import ButtonBase from '@mui/material/ButtonBase'
import { Button } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useMediaQuery, useTheme } from '@mui/material'

interface MultiSelectProps {
  navList?: boolean
  label: string
  onClick?: () => void
}

export const LoginButton: FC<MultiSelectProps> = (props) => {
  const { label, onClick = () => {}, navList = false } = props
  const theme = useTheme()
  const isBrowser = useMediaQuery(theme.breakpoints.up('md'))

  const renderButton = () => {
    if (isBrowser && !navList) {
      return (
        <ButtonBase
          disableRipple
          onClick={onClick}
          sx={{
            alignItems: 'center',
            borderRadius: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            px: '16px',
            py: '8px',
            textAlign: 'left',
            '&:hover': {
              backgroundColor: 'action.hover'
            }
          }}
        >
          <Typography
            component='span'
            variant='subtitle1'
          >
            {label}
          </Typography>
        </ButtonBase>
      )
    }
    return (
      <Button
        variant='contained'
        data-testid='SignUpButton'
        onClick={onClick}
        color='inherit'
        sx={{ width: '100%', mt: 1 }}
      >
        Log in
      </Button>
    )
  }

  return (
    <Box
      component='li'
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%'
      }}
    >
      {renderButton()}
    </Box>
  )
}

LoginButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func
}
