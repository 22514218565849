import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'

import { TopNavItem } from '../TopNavItem'

interface MultiSelectProps {
  label: string
  // Same as type as the value received above
  onChange?: (value: string) => void
  options: { label: string; value: string; icon: () => JSX.Element }[]
}
export const PopoverMenu: FC<MultiSelectProps> = (props) => {
  const { label, onChange, options, ...other } = props

  const passingProps = {
    active: false,
    disabled: false,
    icon: null,
    items: options,
    label: '',
    title: label
  }

  return (
    <TopNavItem
      onChange={onChange}
      {...passingProps}
    />
  )
}

PopoverMenu.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired
}
