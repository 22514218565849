/* eslint-disable @typescript-eslint/ban-ts-comment */
import { UserProfileLink as View } from 'ui-components'
import { useAuthContext } from 'user-auth'

export const UserProfile = (props) => {
  const { user } = useAuthContext()

  if (!user) return null

  return <View user={user} />
}
