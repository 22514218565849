import React, { useState } from 'react'
import Avatar from '@mui/material/Avatar'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import SvgIcon from '@mui/material/SvgIcon'
import User01Icon from '@untitled-ui/icons-react/build/esm/User01'
import { usePopover } from '~ui-components/hooks/usePopover'
import { User } from '~ui-components/types/__generated/gql/graphql'
import { useIsMobile } from '~ui-components/hooks'
import { MobileNav } from '~ui-components/components/organisms/AppHeader/Anonymous/AppHeaderLayout/Mobile/Drawer'
import { useMobileNav } from '~ui-components/components/organisms/AppHeader/Anonymous/AppHeaderLayout/Mobile/hooks/useMobileNav'
import Button from '@mui/material/Button'

import {
  AccountPopover,
  AccountPopoverProps,
  AccountPopoverUser,
  AccountPopoverWorkspace
} from './account-popover'

import type { FC } from 'react'
import { set } from 'lodash'

export type AccountButtonUser = Pick<User, 'avatar'> & AccountPopoverUser

export type AccountButtonProps = {
  user?: AccountButtonUser
  workspaces?: AccountPopoverWorkspace[]
  currentWorkspace?: any
  slots?: AccountPopoverProps['slots']
  onLogout?: () => void
}

export const AccountButton: FC<AccountButtonProps> = ({
  user,
  onLogout,
  workspaces,
  currentWorkspace,
  slots
}) => {
  const popover = usePopover<HTMLButtonElement>()
  const [open, setOpen] = useState(false)
  const isMobile = useIsMobile()
  const mobileNav = useMobileNav()

  return (
    <>
      <Box
        data-testid='AvatarButton-Avatar'
        component={ButtonBase}
        onClick={() => {
          setOpen(!open)
        }}
        ref={popover.anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          borderWidth: 2,
          borderStyle: 'solid',
          borderColor: 'divider',
          height: 40,
          width: 40,
          borderRadius: '50%'
        }}
      >
        <Avatar
          sx={{ height: 40, width: 40 }}
          src={currentWorkspace?.logo ?? undefined}
          onClick={() => mobileNav.handleOpen()}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
        {isMobile ? (
          <Box>
            <MobileNav
              open={mobileNav.open}
              onClose={mobileNav.handleClose}
              slots={{
                Body: () => {
                  return (
                    <>
                      {slots && slots.Workspaces && <slots.Workspaces />}
                      {slots && slots.Workspace && <slots.Workspace />}
                      {slots && slots.Community && <slots.Community />}
                    </>
                  )
                },
                Footer: () => (
                  <>
                    {slots && slots.UserProfile && <slots.UserProfile />}
                    <Box
                      sx={{
                        display: 'flex',
                        p: 1,
                        justifyContent: 'center'
                      }}
                    >
                      <Button
                        data-testid='AccountPopover-LogoutButton'
                        color='inherit'
                        onClick={onLogout}
                        size='small'
                        variant='text'
                      >
                        Logout
                      </Button>
                    </Box>
                  </>
                ),
                Logo: () => <>{slots && slots.Logo && <slots.Logo />} </>
              }}
            />
          </Box>
        ) : (
          <AccountPopover
            slots={{ ...slots }}
            anchorEl={popover.anchorRef.current}
            onClose={() => setOpen(false)}
            open={open}
            user={user}
            workspaces={workspaces}
            onLogout={() => {
              onLogout?.()
              setOpen(false)
            }}
          />
        )}
      </Box>
    </>
  )
}
