import { Container, Skeleton } from '@mui/material'
import { Masonry } from '@mui/lab'

import { Card, CardProps } from '~ui-components/components/molecules/Card'

export type PopularMapsProps<T> = {
  isLoading: boolean
  maps: T[]
  getKey: (map: T) => string
  getHref: (map: T) => CardProps['href']
  getCover: (map: T) => CardProps['cover']
  getTitle: (map: T) => CardProps['title']
  getAvatarProps: (map: T) => CardProps['avatar']
  getFooterProps: (map: T) => CardProps['footer']
}

export const PopularMaps = <T,>({
  isLoading,
  maps,
  getKey,
  getHref,
  getCover,
  getTitle,
  getAvatarProps,
  getFooterProps
}: PopularMapsProps<T>) => {
  return (
    <Container maxWidth={false}>
      <Masonry
        columns={{ xs: 1, md: 2, lg: 3, xxl: 4 }}
        spacing={4}
        sx={{ margin: 'unset' }}
      >
        {isLoading
          ? new Array(4).fill(null).map((_, i) => (
              <Skeleton
                key={i}
                sx={{ maxWidth: 'unset' }}
              >
                <Card
                  href='https://dummy-url'
                  cover='https://dummy-url'
                  title='Dummy title'
                />
              </Skeleton>
            ))
          : maps.map((map) => (
              <Card
                key={getKey(map)}
                href={getHref(map)}
                cover={getCover(map)}
                title={getTitle(map)}
                avatar={getAvatarProps(map)}
                footer={getFooterProps(map)}
              />
            ))}
      </Masonry>
    </Container>
  )
}
