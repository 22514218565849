import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

import { Pagination } from '~ui-components/components/atoms/Pagination'
import { MapListStack } from '~ui-components/components/molecules/MapLists/MapListStack'

const MapOpenSearchResults = (props) => {
  const {
    tagHref,
    loading,
    maps = [],
    pagination = {},
    onPageChange,
    onTagClick
  } = props
  const { page, total, limit } = pagination

  return (
    <Box data-testid='MapOpenSearchResults'>
      <MapListStack
        tagHref={tagHref}
        loading={loading}
        maps={maps}
        onTagClick={onTagClick}
      />

      {!isEmpty(pagination) && !isEmpty(maps) ? (
        <>
          <Box
            my={5}
            display='flex'
            justifyContent='center'
          >
            <Pagination
              data-testid='MapOpenSearchPagination'
              page={page}
              total={total}
              limit={limit}
              onChange={onPageChange}
            />
          </Box>
        </>
      ) : null}
    </Box>
  )
}

const MapOpenSearchResultsPropTypes = {
  tagHref: PropTypes.func,
  maps: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    page: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    total: PropTypes.number,
    limit: PropTypes.number
  }),
  onPageChange: PropTypes.func,
  onTagClick: PropTypes.func
}

MapOpenSearchResults.propTypes = MapOpenSearchResultsPropTypes

export { MapOpenSearchResults, MapOpenSearchResultsPropTypes }
export default MapOpenSearchResults
