import { generateCategoryColourRamp } from '../get-colour-ramp';
import { StyleOptions } from '../../types';
import { MS_CATEGORY_COLOUR_PALETTE } from "../constants";
import { generateCategories } from '../get-class-breaks';
import { generateCategoryColourPalette } from '../get-colour-palette';
import { generateLegend, generateLine } from "./category-style-utils";

const DEFAULT_BOUNDARY_OPTIONS: StyleOptions = {
  aggregateBoundary: 'MS_UUID',
  aggregateProperty: 'testCategory',
  source: 'mapstack',
}

function getCategoryStyle(geojson, options = DEFAULT_BOUNDARY_OPTIONS) {

  const featureCategoryRecord = generateCategories(geojson.features, options.aggregateProperty)
  const colourPalette: string[] = generateCategoryColourPalette(Object.keys(featureCategoryRecord).length, MS_CATEGORY_COLOUR_PALETTE)

  const colourRamp = generateCategoryColourRamp(featureCategoryRecord, colourPalette)

  const fill = {
    id: 'Mapstack Visualization Fill',
    type: 'fill',
    source: options.source,
    paint: {
      'fill-color': ['match', ['get', options.aggregateBoundary], ...colourRamp],
      'fill-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.6,
        0.9
      ]
    }
  }

  if (options.sourceLayer) fill['source-layer'] = options.sourceLayer

  const line = generateLine(
    options.source,
    options.sourceLayer,
    options.filter
  )

  const legend = generateLegend(
    Object.keys(featureCategoryRecord),
    colourPalette,
    options.aggregateProperty
  )


  return {
    fill,
    line,
    legend
  };
}

export { getCategoryStyle };
