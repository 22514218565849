import { Line } from '../../../../types'

function generateLine(source, sourceLayer?, filter?): Line {
  const line = {
    id: 'Mapstack Visualization Line',
    type: 'line',
    source: source,
    paint: {
      'line-color': '#333',
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        3,
        0,
        5,
        1,
        10,
        2,
        11,
        4,
        12,
        6
      ]
    }
  }

  if (sourceLayer) line['source-layer'] = sourceLayer
  if (filter) line['filter'] = filter
  return line
}

export { generateLine }
