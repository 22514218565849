/* eslint-disable no-useless-escape */
const noSpecial = (string: string = '') =>
  string.replace(
    /[^a-zA-Z0-9\-]^[\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]/g,
    '-'
  )
const replaceSpace = (string = '') => string.replace(/\s/g, '-')
const lowerCase = (string = '') => string.toLowerCase()
const noPunctuation = (string = '') => string.replace(/['"]/g, '')

const slugOf = (inputString: string): string => {
  inputString = String(inputString)
  const callbacks = [lowerCase, replaceSpace, noPunctuation, noSpecial]
  const cleanedString = callbacks.reduce((previous, next) => {
    return next(previous)
  }, inputString)
  return encodeURIComponent(cleanedString)
}

export { slugOf }
