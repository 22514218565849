import { Alert, Box } from '@mui/material'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import Stack from '@mui/material/Stack'
import Switch from '@mui/material/Switch'
import Typography from '@mui/material/Typography'
import React from 'react'

import { useIsMobile } from '~ui-components/hooks/useIsMobile'

interface Option {
  title: string
  value: string
}

const LayoutOptions: Option[] = [
  {
    title: 'Portrait',
    value: 'portrait'
  },
  {
    title: 'Landscape',
    value: 'landscape'
  }
]

const SizeOptions: Option[] = [
  {
    title: 'Social media',
    value: 'social-media'
  },
  {
    title: 'Presentation',
    value: 'presentation'
  }
]

type InfographicProps = {
  layout: 'portrait' | 'landscape'
  title: boolean
  legend: boolean
  showLegend: boolean
  onDownload?: () => void
  onCopyLink?: () => void
  onLayoutChange?: (value: string) => void
  onSizeChange?: (value: string) => void
  onTitleChange?: (value: boolean) => void
  onLegendChange?: (value: boolean) => void
}

export function Infographic(props: InfographicProps) {
  const {
    layout,
    title = false,
    legend = false,
    showLegend = false,
    onDownload,
    onCopyLink,
    onLayoutChange,
    onTitleChange,
    onLegendChange,
    onSizeChange
  } = props
  const [value, setValue] = React.useState<'portrait' | 'landscape'>(layout)
  const [size, setSize] = React.useState<'social-media' | 'presentation'>(
    'social-media'
  )
  const [createLinkText, setCreateLinkText] = React.useState('Create link')

  const mobile = useIsMobile()

  const handleChange = (layout: string) => {
    setValue(layout as 'portrait' | 'landscape')
    if (onLayoutChange) {
      onLayoutChange(layout)
    }
  }

  const handleSizeChange = (size: string) => {
    setSize(size as 'social-media' | 'presentation')
    if (onSizeChange) {
      onSizeChange(size)
    }
  }

  const handleCopyLink = () => {
    setCreateLinkText('Link copied')
    onCopyLink && onCopyLink()
  }

  if (mobile) {
    return (
      <Alert
        variant='filled'
        severity='info'
      >
        This feature is not currently available on mobile
      </Alert>
    )
  }

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Box>
          <Typography
            color='text.secondary'
            variant='overline'
          >
            LAYOUT
          </Typography>
          {LayoutOptions.map((option, index) => (
            <Stack
              key={`LayoutOption-${index}`}
              direction='row'
              spacing={2}
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex'
              }}
              onClick={(): void => handleChange(option.value)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='subtitle2'>{option.title}</Typography>
              </Box>
              <Radio
                checked={value === option.value}
                color='primary'
              />
            </Stack>
          ))}
        </Box>
        <Box sx={{ mt: 1 }}>
          <Typography
            color='text.secondary'
            variant='overline'
          >
            SIZE
          </Typography>
          {SizeOptions.map((option, index) => (
            <Stack
              key={`SizeOption-${index}`}
              direction='row'
              spacing={2}
              sx={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex'
              }}
              onClick={(): void => handleSizeChange(option.value)}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant='subtitle2'>{option.title}</Typography>
              </Box>
              <Radio
                checked={size === option.value}
                color='primary'
              />
            </Stack>
          ))}
        </Box>
        <Box>
          <Stack
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            spacing={3}
          >
            <Stack spacing={1}>
              <Typography
                color='text.secondary'
                variant='overline'
              >
                TITLE
              </Typography>
            </Stack>
            <Switch
              checked={title}
              color='primary'
              edge='start'
              name='isVerified'
              onChange={(e) => onTitleChange && onTitleChange(e.target.checked)}
              value={title}
            />
          </Stack>
          {showLegend && (
            <Stack
              alignItems='center'
              direction='row'
              justifyContent='space-between'
              spacing={3}
            >
              <Stack spacing={1}>
                <Typography
                  color='text.secondary'
                  variant='overline'
                >
                  LEGEND
                </Typography>
              </Stack>
              <Switch
                checked={legend}
                color='primary'
                edge='start'
                name='isVerified'
                onChange={(e) =>
                  onLegendChange && onLegendChange(e.target.checked)
                }
                value={legend}
              />
            </Stack>
          )}
        </Box>
      </Box>
      <Button
        color='secondary'
        type='submit'
        variant='contained'
        onClick={onDownload}
      >
        Download
      </Button>
      <Button
        sx={{ mt: 2 }}
        color='inherit'
        onMouseOut={() => setCreateLinkText('Create link')}
        onClick={handleCopyLink}
      >
        {createLinkText}
      </Button>
    </>
  )
}
