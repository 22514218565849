import { Amplify } from 'aws-amplify'
import {
  GetMapByIdQuery,
  GetMapByIdQueryVariables
} from '~map-viewer/types/__generated/gql/graphql'

import { graphierQL, isAuthenticated } from 'utils'

const getMapById = async (
  mapId: string,
  customContext?: typeof Amplify,
  embedKey?: string
) => {
  const bool = await isAuthenticated(customContext)
  const data = await graphierQL<GetMapByIdQuery, GetMapByIdQueryVariables>(
    {
      useToken: bool,
      query: getMapByIdQuery,
      variables: { id: mapId, embedKey }
    },
    customContext
  )

  const responseData = data.getMapById ?? {}

  const { readme } = responseData

  if (readme) readme.nodes = readme.nodes.map((node) => JSON.parse(node))

  return {
    ...responseData,
    readme
  }
}

const getMapByIdQuery = /* GraphQL */ `
  query GetMapById($id: ID!, $embedKey: String) {
    getMapById(id: $id, embedKey: $embedKey) {
      id
      createdAt
      updatedAt
      dataId
      dataReady
      name
      description
      views
      likes
      remixes
      bbox
      centroid
      isPublic
      liked
      embedKey
      properties {
        name
        type
        visualisation
        isAggregate
        isPercentage
        isDefaultVisualisation
        isTitle
        classBreaks
      }
      license {
        name
        url
      }
      source {
        name
        url
      }
      readme {
        nodes
        createdAt
        updatedAt
      }
      workspace {
        id
        inviteId
        name
        description
        logo
        createdAt
        memberCollection {
          total
          items {
            user {
              id
              firstName
              lastName
              avatar
              email
            }
          }
        }
        guestCollection {
          total
          items {
            user {
              id
              firstName
              lastName
              avatar
              email
            }
          }
        }
      }
      creator {
        id
        firstName
        lastName
        email
        avatar
        createdAt
      }
      parentMap {
        id
        name
        workspace {
          id
          name
        }
        creator {
          id
          firstName
          lastName
        }
      }
      mapTagCollection {
        total
        items {
          tag {
            name
            id
            type
            description
            count
          }
        }
      }
      mapRemixCollection {
        total
      }
      type
      mapGuestAccessCollection {
        total
        items {
          id
          workspaceId
          userId
          mapId
          createdAt
          user {
            id
            firstName
            lastName
            email
            avatar
            createdAt
          }
          invitedUser {
            id
            email
            createdAt
          }
        }
      }
    }
  }
`

export { getMapById, getMapByIdQuery }
