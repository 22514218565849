export type AddressGeocoderVariant = {
  type: 'redirect'
  payload: {
    fileName: string
    extension: string
  }
}

export type ReadyVariant = {
  type: 'ready'
}

export function isAddressGeocoderVariant(
  data: any
): data is AddressGeocoderVariant {
  return (
    data['type'] === 'redirect' &&
    'fileName' in data.payload &&
    'extension' in data.payload
  )
}

export function isReadyVariant(data: any): data is ReadyVariant {
  return data['type'] === 'ready'
}
