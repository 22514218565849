import { createSelector, createSlice } from '@reduxjs/toolkit'
import { isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import { MapStatus } from '~map-viewer/constants/MapStatus'
import mapApi from '~map-viewer/services/map'

export const initialMapState = {
  mapType: 'default',
  mapStatus: MapStatus.IDLE,
  isOwner: false,
  mapData: {},
  datasets: [],
  fitBounds: [],
  jsonFeatures: {},
  currentBounds: [],
  hasDataSet: false,
  isDataLoading: true,
  selectedFeature: {},
  currentFeature: {},
  isMapDeleting: false
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: initialMapState,
  reducers: {
    clearMapData: () => {},

    setMapData: (state, action) => {
      state.mapData = action.payload
    },

    setJsonFeatures: (state, action) => {
      state.jsonFeatures = action.payload
    },

    addDataset: (state, action) => {
      state.datasets.push(action.payload)
    },

    setCurrentBounds: (state, action) => {
      state.currentBounds = action.payload
    },

    setFitBounds: (state, action) => {
      if (
        Object.values(action.payload).some(
          (value) => value === Infinity || value === -Infinity
        )
      )
        return
      state.fitBounds = action.payload
    },

    setHasDataSet: (state, action) => {
      const boolean = action.payload
      state.hasDataSet = boolean
    },

    setIsDataLoading: (state, action) => {
      state.isDataLoading = action.payload
    },

    setSelectedFeature: (state, action) => {
      state.selectedFeature = action.payload
    },

    setCurrentFeature: (state, action) => {
      state.currentFeature = action.payload
    },

    setMapStatus: (state, action) => {
      state.mapStatus = action.payload
    },

    setMapType: (state, action) => {
      state.mapType = action.payload
    },

    setIsOwner: (state, action) => {
      state.isOwner = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(mapApi.endpoints.deleteMapById.matchPending, (state) => {
        state.isMapDeleting = true
      })
      .addMatcher(mapApi.endpoints.deleteMapById.matchFulfilled, (state) => {
        state.isMapDeleting = false
      })
      .addMatcher(mapApi.endpoints.deleteMapById.matchRejected, (state) => {
        state.isMapDeleting = false
      })

    builder.addMatcher(
      mapApi.endpoints.saveReadme.matchFulfilled,
      (state, action) => {
        const readme = action.payload.saveReadme
        readme.nodes = readme.nodes.map(JSON.parse)
        state.mapData = {
          ...state.mapData,
          readme
        }
      }
    )
  }
})

export const {
  setMapData,
  addDataset,
  setFitBounds,
  setJsonFeatures,
  setCurrentBounds,
  clearMapData,
  setHasDataSet,
  setIsDataLoading,
  setSelectedFeature,
  setCurrentFeature,
  setMapStatus,
  setMapType,
  setIsOwner
} = mapSlice.actions

const selectMapData = (state) => state.map.mapData
const selectCurrentBounds = (state) => state.map.currentBounds
const selectDataSets = (state) => state.map.datasets
const selectFitBounds = (state) => state.map.fitBounds
const selectJsonFeatures = (state) => state.map.jsonFeatures
const selectMapId = (state) => state.map.mapData?.id
const selectMapName = (state) => state.map.mapData?.name
const selectMapDescription = (state) => state.map.mapData?.description
const selectHasDataSet = (state) => state.map.hasDataSet
const selectIsDataLoading = (state) => state.map.isDataLoading
const selectSelectedFeature = (state) => state.map.selectedFeature
const selectCurrentFeature = (state) => state.map.currentFeature
const selectMapType = (state) => state.map.mapType
const selectIsMapDeleting = (state) => state.map.isMapDeleting
const selectProperties = (state) => state.map.mapData?.properties || []
const selectReadme = (state) => state.map?.mapData?.readme
const selectMapTagCollection = (state) =>
  state.map?.mapData?.mapTagCollection || {}
const selectParentMap = (state) => state.map?.mapData?.parentMap || {}

const memoizedProperties = createSelector(
  [selectProperties],
  (properties) => properties ?? []
)
const selectIsOwner = (state) => state.map.isOwner
const selectMapStatus = (state) => state.map.mapStatus

const selectDefaultVisualisation = createSelector(
  [selectProperties],
  (properties) => {
    const property = properties.find(
      (property) => property.isDefaultVisualisation
    )
    const { visualisation, name, classBreaks } = property ?? {}
    return { value: name, type: visualisation, classBreaks }
  }
)

const selectVisualisations = createSelector(
  [selectProperties],
  (properties) => {
    return properties
      .filter((property) => property.visualisation !== 'none')
      .map((property) => {
        const { visualisation, name, classBreaks } = property
        return { value: name, type: visualisation, classBreaks }
      })
  }
)

const selectTitleProperty = createSelector([selectProperties], (properties) => {
  return properties.find((property) => property.isTitle) || {}
})

const selectFeatureInfo = createSelector(
  [selectProperties, selectSelectedFeature],
  (properties, selectedFeature) => {
    if (isEmpty(selectedFeature)) return {}
    const { id } = selectedFeature

    const featureProperties = []
    properties.forEach((property) => {
      featureProperties.push({
        ...property,
        value: selectedFeature.properties[property.name]
      })
    })

    return { id, properties: featureProperties }
  }
)

const selectVisualisationProperties = createSelector(
  [selectProperties],
  (properties) => {
    return properties.filter((property) => property.visualisation !== 'none')
  }
)

const selectHasReadme = createSelector(
  [selectReadme],
  (readme) => !isEmpty(readme?.nodes)
)

const selectTags = createSelector(
  [selectMapTagCollection],
  (mapTagCollection) => {
    return mapTagCollection?.items?.map((item) => item.tag) || []
  }
)

const selectHasParentMap = createSelector([selectParentMap], (parentMap) => {
  return !isEmpty(parentMap)
})

export const useMap = () => useSelector(selectMapData)

export const useCurrentBounds = () => useSelector(selectCurrentBounds)

export const useDataSets = () => useSelector(selectDataSets)

export const useFitBounds = () => useSelector(selectFitBounds)

export const useJsonFeatures = () => useSelector(selectJsonFeatures)

export const useMapId = () => useSelector(selectMapId)

export const useMapName = () => useSelector(selectMapName)

export const useMapDescription = () => useSelector(selectMapDescription)

export const useProperties = () => useSelector(memoizedProperties)

export const useDefaultVisualisation = () =>
  useSelector(selectDefaultVisualisation)

export const useVisualisations = () => useSelector(selectVisualisations)

export const useTitleProperty = () => useSelector(selectTitleProperty)

export const useHasDataSet = () => useSelector(selectHasDataSet)

export const useIsDataLoading = () => useSelector(selectIsDataLoading)

export const useCurrentFeature = () => useSelector(selectCurrentFeature)

export const useMapType = () => useSelector(selectMapType)

export const useFeatureInfo = () => useSelector(selectFeatureInfo)

export const useIsMapDeleting = () => useSelector(selectIsMapDeleting)

export const useIsOwner = () => useSelector(selectIsOwner)

export const useMapStatus = () => useSelector(selectMapStatus)

export const useVisualisationProperties = () =>
  useSelector(selectVisualisationProperties)

export const useHasReadme = () => useSelector(selectHasReadme)

export const useTags = () => useSelector(selectTags)

export const useParentMap = () => useSelector(selectParentMap)

export const useHasParentMap = () => useSelector(selectHasParentMap)

export default mapSlice.reducer
