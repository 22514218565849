import { StyleOutput } from '../../types'

function getMarkerStyle(
  options = { selected: 'mapstack-no-value', property: 'id' }
): StyleOutput {
  const symbol = {
    id: 'Mapstack Visualization Symbol',
    type: 'circle',
    source: 'mapstack-points',
    paint: {
      // Use a 'match' expression to conditionally set the circle color
      'circle-color': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        '#ffc65c',
        ['match',
          ['get', options.property], // Get the 'id' property of the feature
          options.selected, '#ffc65c', // If id matches, set color to yellow
          '#ee7b42' // Default color for other features
        ]],
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        12,  // Full opacity when hovered
        8 // Reduced opacity normally
      ],
      'circle-stroke-width': 2,
      'circle-stroke-color': '#333',
      'circle-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.75,  // reduced opacity when hovered
        1 // Full opacity normally
      ]
    }
  }

  const styleOutput: StyleOutput = {
    symbol
  }

  return styleOutput
}

export { getMarkerStyle }
