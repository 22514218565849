import { NextSeo, NextSeoProps } from 'next-seo'
import { OpenGraphMedia } from 'next-seo/lib/types'

import { FC } from 'react'

const DEFAULT_TITLE = "mapstack - The World's Location Data, Mapped"
const DEFAULT_DESCRIPTION =
  'Give your map data the home it deserves with mapstack - open, available to all, and free to host forever as interactive web maps.'
const DEFAULT_URL = `https://${process.env.DOMAIN_NAME}`
const DEFAULT_IMAGE: OpenGraphMedia = {
  width: 1200,
  height: 630,
  url: `https://${process.env.DOMAIN_NAME}/images/og_image.webp`,
  secureUrl: `https://${process.env.DOMAIN_NAME}/images/og_image.webp`,
  alt: "mapstack - The World's Location Data, Mapped",
  type: 'image/webp'
}

export type SeoProps = Omit<
  NextSeoProps,
  'title' | 'description' | 'url' | 'image'
> & {
  title?: string
  description?: string
  url?: string
  image?: OpenGraphMedia
}

export const Seo: FC<SeoProps> = ({
  title,
  description,
  url,
  image,
  ...restNextSeoProps
}) => {
  const twitterProps: NextSeoProps['twitter'] = {
    cardType: 'summary_large_image'
  }
  const openGraphProps: NextSeoProps['openGraph'] = {
    description: description ?? DEFAULT_DESCRIPTION,
    siteName: 'mapstack',
    title: title ?? DEFAULT_TITLE,
    type: 'website',
    url: url ?? DEFAULT_URL,
    images: [image ?? DEFAULT_IMAGE]
  }
  const nextSeoProps: NextSeoProps = {
    ...restNextSeoProps,
    title: title ?? DEFAULT_TITLE,
    description: description ?? DEFAULT_DESCRIPTION,
    titleTemplate: '%s | Mapstack',
    defaultTitle: "mapstack - The World's Location Data, Mapped",
    twitter: twitterProps,
    openGraph: openGraphProps
  }

  return <NextSeo {...nextSeoProps} />
}
