import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

import { TAG_TYPES } from '~map-creator/core/assets/constants'
import mapApi from '~map-creator/core/services/map'
import tagApi from '~map-creator/core/services/tag'

const initialState = {
  inputTags: {
    value: '',
    options: [],
    invalid: undefined
  },
  mapTags: [],
  coverageArea: {},
  featureType: {},
  stepIndexes: {}
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setCoverageAreaTag: (state, action) => {
      state.coverageArea = action.payload
    },
    setFeatureTypeTag: (state, action) => {
      state.featureType = action.payload
    },
    setMapTags: (state, action) => {
      state.mapTags = action.payload
    },
    setInputTagsValue: (state, action) => {
      state.inputTags.value = action.payload
    },
    setInputTagsInvalid: (state, action) => {
      state.inputTags.invalid = action.payload
    },
    setStepIndexes: (state, action) => {
      state.stepIndexes = action.payload
    }
  },

  extraReducers: (builder) => {
    builder
      .addMatcher(
        tagApi.endpoints.searchTags.matchFulfilled,
        (state, action) => {
          const tags = action.payload
          state.inputTags.options = tags
        }
      )
      .addMatcher(
        mapApi.endpoints.getMapById.matchFulfilled,
        (state, action) => {
          const map = action.payload
          const { coverageAreaTag, featureTypeTag, topicTags } =
            parseMapData(map)

          state.inputTags.options = topicTags
            .concat([coverageAreaTag, featureTypeTag])
            .filter(Boolean)

          if (coverageAreaTag) state.coverageArea = coverageAreaTag
          if (featureTypeTag) state.featureType = featureTypeTag
          if (topicTags.length) state.mapTags = topicTags
        }
      )
  }
})

export function parseMapData(map) {
  const coverageAreaTag = map.mapTagCollection.items.find(
    (i) => i.tag.type === TAG_TYPES.COVERAGE_AREA
  )?.tag
  const featureTypeTag = map.mapTagCollection.items.find(
    (i) => i.tag.type === TAG_TYPES.FEATURE_TYPE
  )?.tag
  const topicTags = map.mapTagCollection.items
    .filter((i) => i.tag.type === TAG_TYPES.TOPIC)
    .map((i) => i.tag)
    .filter(Boolean)

  return {
    coverageAreaTag,
    featureTypeTag,
    topicTags
  }
}

const inputTags = (state) => {
  return state.ui.inputTags
}

const mapTags = (state) => state.ui.mapTags

const selectTagIds = (state) => {
  const tags = state.ui.mapTags.concat([
    state.ui.coverageArea,
    state.ui.featureType
  ])
  return tags.map((tag) => tag.id).filter(Boolean)
}

const selectMapTagCollection = (state) => {
  const tags = state.ui.mapTags.concat([
    state.ui.coverageArea,
    state.ui.featureType
  ])

  return {
    total: tags.length,
    items: tags.map((tag) => ({ tag }))
  }
}

const useInputTags = () => {
  return useSelector(inputTags)
}

const useMapTags = () => useSelector(mapTags)

const useTagIds = () => {
  return useSelector(selectTagIds)
}

const useMapTagCollection = () => {
  return useSelector(selectMapTagCollection)
}

const useStepIndexes = () => useSelector((state) => state.ui.stepIndexes)

export {
  useInputTags,
  useMapTagCollection,
  useMapTags,
  useStepIndexes,
  useTagIds
}

export const {
  setInputTagsValue,
  setMapTags,
  setInputTagsInvalid,
  setCoverageAreaTag,
  setFeatureTypeTag,
  setStepIndexes
} = uiSlice.actions

export default uiSlice.reducer
