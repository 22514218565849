import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { ChangeEvent, FC } from 'react'

import { Pagination } from '~ui-components/components/atoms/Pagination'
import {
  EmptyMapListFallback,
  EmptyMapListFallbackProps
} from '~ui-components/components/molecules/EmptyMapListFallback'
import {
  MapListStack,
  MapListStackProps
} from '~ui-components/components/molecules/MapLists/MapListStack'

export type MapListWithFallbackProps = MapListStackProps &
  EmptyMapListFallbackProps & {
    pagination: {
      page: number
      total: number
      limit: number
    }
    onPageChange: (e: ChangeEvent<unknown>, page: number) => void
  }

export const MapListWithFallback: FC<MapListWithFallbackProps> = ({
  isWorkspaceGuest = false,
  loading,
  maps = [],
  noResultsText,
  defaultMapUrl,
  canCreateMap = false,
  onCreateMapClick,
  getMapAvatar,
  pagination,
  onPageChange
}) => {
  if (isEmpty(maps)) {
    return (
      <EmptyMapListFallback
        isWorkspaceGuest={isWorkspaceGuest}
        noResultsText={noResultsText}
        defaultMapUrl={defaultMapUrl}
        canCreateMap={canCreateMap}
        onCreateMapClick={onCreateMapClick}
      />
    )
  }

  return (
    <Box data-testid='MapList'>
      <MapListStack
        loading={loading}
        maps={maps}
        getMapAvatar={getMapAvatar}
      />
      {!isEmpty(pagination) && !isEmpty(maps) && !loading && (
        <Box
          py={3}
          display='flex'
          justifyContent='center'
        >
          <Pagination
            data-testid='MapListPagination'
            page={pagination.page}
            total={pagination.total}
            limit={pagination.limit}
            onChange={onPageChange}
          />
        </Box>
      )}
    </Box>
  )
}
