import {
  Box,
  Divider,
  IconButton,
  Card as MuiCard,
  Skeleton,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material'
import { Eye, Heart, Star01 } from '@untitled-ui/icons-react/build/esm'
import type { FC } from 'react'

export const CardSkeleton: FC = () => {
  return (
    <MuiCard>
      <Box sx={{ p: 2 }}>
        <Box
          component={Skeleton}
          sx={{ height: '250px' }}
        />
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            alignItems: 'center',
            mt: 2
          }}
        >
          <Box
            component={Skeleton}
            width={42}
            height={40}
            variant='circular'
          />
          <Box>
            <Box
              component={Skeleton}
              width={200}
              height={15}
              variant='rounded'
            />
            <Box
              component={Skeleton}
              width={250}
              height={15}
              sx={{ mt: 1 }}
              variant='rounded'
            />
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          pl: 2,
          pr: 3,
          py: 2
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <SvgIcon>
            <Star01 />
          </SvgIcon>
          <Typography
            sx={{ ml: 1, width: 30 }}
            component={Skeleton}
            color='text.secondary'
            variant='subtitle2'
          ></Typography>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            ml: 2
          }}
        >
          <SvgIcon>
            <Eye />
          </SvgIcon>
          <Typography
            component={Skeleton}
            color='text.secondary'
            sx={{ ml: 1, width: 30 }}
            variant='subtitle2'
          ></Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
    </MuiCard>
  )
}
