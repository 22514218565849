import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { graphierQL, isAuthenticated } from 'utils'
import {
  SearchMapsQuery,
  SearchMapsQueryVariables
} from '~search-box/types/__generated/gql/graphql'

const querySearchMaps = /* GraphQL */ `
  query SearchMaps(
    $coords: Coordinates
    $feedType: FEED_TYPES!
    $from: Int
    $query: String
    $size: Int
    $tagIds: [String!]
  ) {
    searchMaps(
      coords: $coords
      feedType: $feedType
      from: $from
      query: $query
      size: $size
      tagIds: $tagIds
    ) {
      total
      items {
        id
        createdAt
        updatedAt
        dataId
        name
        description
        likes
        views
        liked
        dataReady
        bbox
        properties {
          name
          type
          isTitle
          isPercentage
          isDefaultVisualisation
          isAggregate
          visualisation
        }
        workspace {
          id
          name
          description
          logo
          createdAt
          inviteId
        }
        mapTagCollection {
          total
          items {
            tag {
              id
              name
              description
              type
              count
            }
          }
        }
      }
    }
  }
`

export type SearchMapsVariables = SearchMapsQueryVariables
export type SearchMapsResults = SearchMapsQuery['searchMaps']

export const useSearchMaps = (
  variables: SearchMapsVariables
): UseQueryResult<SearchMapsResults> => {
  const query = useQuery({
    enabled: true,
    queryKey: ['searchMaps'],
    queryFn: async () => {
      const bool = await isAuthenticated()
      return graphierQL<SearchMapsQuery, SearchMapsVariables>({
        useToken: !!bool,
        query: querySearchMaps,
        variables
      }).then((res) => res.searchMaps)
    }
  })

  useEffect(() => {
    query.refetch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return query
}
