import * as qs from 'qs'
import { baseUrl } from '~utils/functions/baseUrl'
import { slugOf } from '~utils/functions/slugOf'

const getInviteLink = ({ workspaceId, workspaceName, inviteId }) => {
  const origin = baseUrl()
  const query = qs.stringify({ inviteId })
  // prettier-ignore
  return `${origin}/workspace/${workspaceId}/${slugOf(workspaceName)}/admin/shared-invite${query ? `?${query}` : ''}`
}

export { getInviteLink }
