import type { ReactNode } from 'react'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import { Dropdown, DropdownMenu, DropdownTrigger } from './dropdown'
import { RouterLink } from '~ui-components/components/atoms'

import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import SvgIcon from '@mui/material/SvgIcon'
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown'
import PropTypes from 'prop-types'
import type { FC } from 'react'

interface Item {
  disabled?: boolean
  external?: boolean
  icon?: () => JSX.Element
  label?: ReactNode
  path?: string
  value: string
}

const renderChildItems = ({
  items,
  depth = 0,
  onChange = () => {}
}: {
  items: Item[]
  depth?: number
  onChange?: (value: string) => void
}) => {
  return items.map((item) => {
    const linkProps = item.path
      ? item.external
        ? {
            component: 'a',
            href: item.path,
            target: '_blank'
          }
        : {
            component: RouterLink,
            href: item.path
          }
      : {}

    // Leaf
    return (
      <ListItemButton
        key={item.value}
        disabled={item.disabled}
        onClick={() => onChange(item.value)}
        sx={{
          borderRadius: 1,
          px: 1.5,
          py: 0.5
        }}
        {...linkProps}
      >
        <SvgIcon
          fontSize='small'
          sx={{ color: 'neutral.400', mr: 1 }}
        >
          {item.icon && item.icon()}
        </SvgIcon>
        <ListItemText
          primary={item.label}
          primaryTypographyProps={{
            sx: {
              color: 'text.secondary',
              fontSize: 14,
              fontWeight: 500
            }
          }}
        />
      </ListItemButton>
    )
  })
}

interface TopNavItemProps {
  active?: boolean
  disabled?: boolean
  external?: boolean
  icon?: ReactNode
  items?: Item[]
  label?: ReactNode
  path?: string
  title: string
  onChange?: (value: string) => void
}

export const TopNavItem: FC<TopNavItemProps> = (props) => {
  const {
    active,
    disabled,
    external,
    items,
    icon,
    label,
    path,
    title,
    onChange
  } = props

  // With dropdown

  if (items) {
    return (
      <Dropdown>
        <DropdownTrigger>
          <Box
            component='li'
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <ButtonBase
              disabled={disabled}
              sx={{
                alignItems: 'center',
                borderRadius: 1,
                display: 'flex',
                justifyContent: 'flex-start',
                px: '16px',
                py: '6px',
                textAlign: 'left',
                width: '100%',
                ...(active && {
                  backgroundColor: 'var(--nav-item-active-bg)'
                }),
                '&:hover': {
                  backgroundColor: 'var(--nav-item-hover-bg)'
                }
              }}
            >
              <Box
                component='span'
                sx={{
                  alignItems: 'center',
                  color: 'var(--nav-item-icon-color)',
                  display: 'inline-flex',
                  justifyContent: 'center',
                  mr: 2,
                  ...(active && {
                    color: 'var(--nav-item-icon-active-color)'
                  })
                }}
              >
                {icon}
              </Box>
              <Box
                component='span'
                sx={{
                  color: 'var(--nav-item-color)',
                  flexGrow: 1,
                  fontFamily: (theme) => theme.typography.fontFamily,
                  fontSize: 14,
                  fontWeight: 600,
                  lineHeight: '24px',
                  whiteSpace: 'nowrap',
                  ...(active && {
                    color: 'var(--nav-item-active-color)'
                  }),
                  ...(disabled && {
                    color: 'var(--nav-item-disabled-color)'
                  })
                }}
              >
                {title}
              </Box>
              <SvgIcon
                sx={{
                  color: 'var(--nav-item-chevron-color)',
                  fontSize: 16,
                  ml: 1
                }}
              >
                <ChevronDownIcon />
              </SvgIcon>
            </ButtonBase>
          </Box>
        </DropdownTrigger>
        <DropdownMenu
          disableScrollLock
          PaperProps={{
            elevation: 8,
            sx: {
              maxWidth: '100%',
              p: 1,
              width: 200
            }
          }}
        >
          {renderChildItems({
            items,
            depth: 0,
            onChange: onChange
          })}
        </DropdownMenu>
      </Dropdown>
    )
  }

  // Without dropdown

  const linkProps = path
    ? external
      ? {
          component: 'a',
          href: path,
          target: '_blank'
        }
      : {
          component: RouterLink,
          href: path
        }
    : {}

  return (
    <li>
      <ButtonBase
        disabled={disabled}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          px: '16px',
          py: '6px',
          textAlign: 'left',
          width: '100%',
          ...(active && {
            backgroundColor: 'var(--nav-item-active-bg)'
          }),
          '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)'
          }
        }}
        {...linkProps}
      >
        {icon && (
          <Box
            component='span'
            sx={{
              alignItems: 'center',
              color: 'var(--nav-item-icon-color)',
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              ...(active && {
                color: 'var(--nav-item-icon-active-color)'
              })
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component='span'
          sx={{
            color: 'var(--nav-item-color)',
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
            lineHeight: '24px',
            whiteSpace: 'nowrap',
            ...(active && {
              color: 'var(--nav-item-active-color)'
            }),
            ...(disabled && {
              color: 'var(--nav-item-disabled-color)'
            })
          }}
        >
          {title}
        </Box>
        {label && (
          <Box
            component='span'
            sx={{ ml: 1 }}
          >
            {label}
          </Box>
        )}
      </ButtonBase>
    </li>
  )
}

TopNavItem.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  external: PropTypes.bool,
  icon: PropTypes.node,
  items: PropTypes.array,
  label: PropTypes.node,
  path: PropTypes.string
}
