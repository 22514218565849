import { Symbol } from '../../../../types'
import { generateCategoryColourRamp } from '../../../get-colour-ramp';

function generateSymbol(
  featureCategoryRecord,
  colourPalette: string[],
  options = { selected: 'mapstack-no-value', property: 'id' }
): Symbol {

  const colourRamp = generateCategoryColourRamp(featureCategoryRecord, colourPalette)

  const symbol = {
    id: 'Mapstack Visualization Symbol',
    type: 'circle',
    source: 'mapstack-points',
    paint: {
      // Use a 'match' expression to conditionally set the circle color
      'circle-color': [
        'match',
        ['get', options.property],
        ...colourRamp
      ],
      'circle-radius': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        12,  // Bigger radius when hovered
        8 // Smaller radius normally
      ],
      'circle-stroke-width': 2,
      'circle-stroke-color': '#333',
      'circle-opacity': [
        'case',
        ['boolean', ['feature-state', 'hover'], false],
        0.75,  // reduced opacity when hovered
        1 // Full opacity normally
      ]
    }
  }

  return symbol
}

export { generateSymbol }
