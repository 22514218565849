import { AppHeaderAccount as View } from 'ui-components'
import {
  useAuthContext,
  useAuthCurrentWorkspace,
  useAuthWorkspaces,
  useFederatedAuth
} from 'user-auth'
import { Community } from './Community'
import { UserProfile } from './UserProfile'
import { Workspace } from './Workspace'
import { Workspaces } from './Workspaces'
import { Logo } from '../../Logo'

const Account = () => {
  const {
    user,
    authenticated,
    loading: isAuthLoading,
    initialized
  } = useAuthContext()

  const { signOut } = useFederatedAuth()
  const workspaces = useAuthWorkspaces()
  const currentWorkspace = useAuthCurrentWorkspace()

  const handleSignOutClick = () => {
    signOut({ preventRedirect: true })
  }

  const isLoading = !initialized || isAuthLoading

  if (!currentWorkspace) return null

  return (
    <View
      loading={isLoading}
      authenticated={authenticated}
      user={user}
      workspaces={workspaces}
      currentWorkspace={currentWorkspace}
      onSignOutClick={handleSignOutClick}
      slots={{
        Logo,
        Workspaces,
        Community,
        Workspace,
        UserProfile
      }}
    />
  )
}

export { Account }
