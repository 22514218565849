/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Stack } from '@mui/material'
import { Fragment } from 'react'
import Skeleton from '~ui-components/components/atoms/Skeleton'
import { AccountButton } from '../../AccountButton'

const AppHeaderAccount = (props) => {
  const {
    loading = false,
    user = {},
    workspaces = [],
    currentWorkspace = {},
    onSignOutClick = () => {}
  } = props

  return (
    <Fragment>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        gap={1}
      >
        <Skeleton
          loading={loading}
          width={40}
          height={40}
          variant='circular'
          sx={{ p: '4px' }}
        >
          {/* @ts-ignore */}
          <AccountButton
            user={user}
            workspaces={workspaces}
            currentWorkspace={currentWorkspace}
            onLogout={onSignOutClick}
            slots={props.slots}
          />
        </Skeleton>
      </Stack>
    </Fragment>
  )
}

export { AppHeaderAccount }
