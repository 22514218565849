import Box from '@mui/material/Box'
import SvgIcon from '@mui/material/SvgIcon'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'
import type { FC } from 'react'

type SearchInputProps = {
  onClick?: (value: string) => void
}

export const SearchIcon: FC<SearchInputProps> = (props) => {
  const { onClick = () => {} } = props

  return (
    <Box
      component='form'
      sx={{
        alignItems: 'center',
        display: 'flex',
        flex: 1
      }}
      onClick={() => onClick}
    >
      <SvgIcon>
        <SearchMdIcon />
      </SvgIcon>
    </Box>
  )
}
