import { Drawer, GlobalStyles, List } from '@mui/material'
import { FC } from 'react'
import { MapBarProps } from '..'

export const DesktopMapBar: FC<MapBarProps> = ({ onClose, slots: Slots }) => {
  return (
    <>
      <GlobalStyles
        styles={{
          ':root': {
            '--map-bar-width': '80px',
            '--sidebar-offset-left': '80px'
          }
        }}
      />

      <Drawer
        open
        anchor='left'
        variant='persistent'
        onClose={onClose}
        slotProps={{
          backdrop: {
            invisible: true
          }
        }}
        ModalProps={{
          disablePortal: true
        }}
        PaperProps={{
          variant: 'elevation',
          elevation: 24,
          sx: {
            width: 'var(--map-bar-width)',
            p: 1,
            borderRadius: 0,
            position: 'relative',
            top: 'unset',
            left: 'unset',
            zIndex: (theme) => theme.zIndex.modal + 4
          }
        }}
      >
        <List disablePadding>
          <Slots.NavButton />
          <Slots.MapSearchButton />
          <Slots.MapFilterButton />
          <Slots.MapDetailsButton />
          <Slots.MapShareButton />
        </List>
      </Drawer>
    </>
  )
}
