import chroma from "chroma-js";
import { DEAFULT_OTHER_CATEGORY_COLOUR } from "../constants";

function getEvenlyDistributedElements(array, n) {
  const length = array.length;
  if (n > length) {
    array.push(DEAFULT_OTHER_CATEGORY_COLOUR)
    return array
  }

  const step = (length - 1) / (n - 1);
  const result: any[] = [];

  for (let i = 0; i < n; i++) {
    const index = Math.round(i * step);
    result.push(array[index]);
  }

  return result;
}

function generateColourPalette(numberOfClasses: number, paletteName: string) {
  if (!(paletteName in chroma.brewer)) throw new Error(`Palette '${paletteName}' is not a valid ColorBrewer palette.`);

  const colors = chroma.scale(chroma.brewer[paletteName]).mode('lab').colors(numberOfClasses + 1).slice(1)
  return colors;
}

function generateCategoryColourPalette(numberOfClasses: number, paletteName: string) {
  if (!(paletteName in chroma.brewer)) throw new Error(`Palette '${paletteName}' is not a valid ColorBrewer palette.`);
  const fullPalette = chroma.brewer[paletteName]

  const colors = getEvenlyDistributedElements(fullPalette, numberOfClasses)

  return colors;
}

export { generateCategoryColourPalette, generateColourPalette };

