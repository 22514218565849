import { Box } from '@mui/material'
import NextLink from 'next/link'

const MaskLink = ({ href, ...props }) => {
  return (
    <NextLink
      href={href}
      passHref
      legacyBehavior
    >
      <Box
        {...props}
        component='a'
        sx={{
          all: 'unset',
          '&:after': {
            content: "' '",
            zIndex: 'maskLink',
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%'
          }
        }}
      />
    </NextLink>
  )
}

export { MaskLink }
export default MaskLink
