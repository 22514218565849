import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import useMediaQuery from '@mui/material/useMediaQuery'
import { alpha } from '@mui/system/colorManipulator'
import {
  Glasses02,
  Globe02,
  Map01,
  Menu01,
  Settings01,
  Star01,
  Users01
} from '@untitled-ui/icons-react/build/esm'

import { AccountButton } from '~ui-components/components/organisms/AccountButton'
import { User } from '~ui-components/types/__generated/gql/graphql'

import type { Theme } from '@mui/material/styles/createTheme'
import type { FC } from 'react'
import {
  NavList,
  NavListProps,
  UserProfileLink,
  WorkspacesListMenu,
  WorkspacesListMenuProps
} from '~ui-components/components/molecules'

const TOP_NAV_HEIGHT = 64
const SIDE_NAV_WIDTH = 280

interface TopNavProps {
  onMobileNavOpen?: () => void
  user?: User
  onLogout?: () => void
  workspaces: WorkspacesListMenuProps['workspaces']
  onWorkspaceClick: NavListProps['onChange']
  onCommuntyClick: NavListProps['onChange']
}

export const TopNav: FC<TopNavProps> = (props) => {
  const {
    onMobileNavOpen,
    user,
    onLogout,
    workspaces,
    onWorkspaceClick,
    onCommuntyClick,
    ...other
  } = props
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

  return (
    <Box
      component='header'
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}
    >
      <Stack
        alignItems='center'
        direction='row'
        justifyContent='space-between'
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01 />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
        <Stack
          alignItems='center'
          direction='row'
          spacing={2}
        >
          <AccountButton
            user={user}
            onLogout={onLogout}
            workspaces={workspaces}
            slots={{
              Workspaces: () => <WorkspacesListMenu workspaces={workspaces} />,
              Community: () => (
                <NavList
                  label='Community'
                  onChange={onCommuntyClick}
                  options={communityOptions}
                />
              ),
              Workspace: () => (
                <NavList
                  label='Workspace'
                  onChange={onWorkspaceClick}
                  options={workspaceOptions}
                />
              ),
              UserProfile: () => <UserProfileLink user={user} />
            }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}

const communityOptions: NavListProps['options'] = [
  {
    value: 'search',
    label: 'Map portal',
    icon: () => (
      <SvgIcon>
        <Globe02 />
      </SvgIcon>
    )
  },
  {
    value: 'explore',
    label: 'Best of mapstack',
    icon: () => (
      <SvgIcon>
        <Star01 />
      </SvgIcon>
    )
  }
  // {
  //   value: 'discord',
  //   label: 'Discord server',
  //   icon: () => (
  //     <SvgIcon>
  //       <Glasses02 />
  //     </SvgIcon>
  //   )
  // }
]

const workspaceOptions: NavListProps['options'] = [
  {
    value: 'maps',
    label: 'Maps',
    icon: () => (
      <SvgIcon>
        <Map01 />
      </SvgIcon>
    )
  },
  {
    value: 'members',
    label: 'Members',
    icon: () => (
      <SvgIcon>
        <Users01 />
      </SvgIcon>
    )
  },
  {
    value: 'settings',
    label: 'Settings',
    icon: () => (
      <SvgIcon>
        <Settings01 />
      </SvgIcon>
    )
  }
]
