import { Box, Paper, Skeleton, Stack, Typography, alpha } from '@mui/material'
import { defaultTo, find, get, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { VISUALISATION, formatFeaturePropertyValue } from 'utils'
import { getSuffix } from '~ui-components/utilities/getSuffix'
import { MapVisualisationColorRamps } from './MapVisualisationColorRamps'
import { getVariant } from './getVariant'

const useProperty = (properties, visualisation) => {
  return useMemo(() => {
    return find(properties, { name: visualisation })
  }, [visualisation, properties])
}

const useTitleProperty = (properties) => {
  return useMemo(() => {
    return find(properties, { isTitle: true }) || {}
  }, [properties])
}

const PrimaryText = (props) => {
  const { variant, text } = props
  const { mobile, desktop } = getVariant(variant)
  return (
    <Box
      data-testid='MapVisualisation-DisplayPrimary'
      component={Typography}
      sx={[
        mobile && {
          fontSize: '1rem',
          fontWeight: 'bold',
          letterSpacing: 'tight'
        },
        desktop && {
          fontSize: '1.25rem',
          fontWeight: 'bold',
          letterSpacing: 'tighter'
        }
      ]}
    >
      {text}
    </Box>
  )
}

const MapVisualisationDesktopDisplay = (props) => {
  const { property, value, primaryText, secondaryText, loading } = props
  const symbol = getSuffix(property)

  if (loading) {
    return (
      <Stack
        direction='column'
        gap={1}
      >
        <Box
          component={Skeleton}
          animation={false}
          width='300px'
          height='30px'
          variant='rounded'
        />
      </Stack>
    )
  }

  return (
    <Stack
      direction='row'
      gap={2}
      alignItems='center'
      flexWrap='wrap'
    >
      <Stack direction='column'>
        <PrimaryText
          variant='desktop'
          text={`${value}${
            symbol ? `(${symbol})` : ''
          } ${primaryText} (${secondaryText})`}
        />
      </Stack>
    </Stack>
  )
}

const MapVisualisation = (props) => {
  const {
    variant = 'desktop',
    feature,
    visualisation,
    visualisationType,
    properties,
    jenks,
    ramps,
    ...rest
  } = props

  const { mobile, desktop } = getVariant(variant)
  const property = useProperty(properties, visualisation)
  const titleProperty = useTitleProperty(properties)

  const value = get(feature, `properties.${visualisation}`)
  const type = get(property, 'type', 'text')
  const formattedValue = formatFeaturePropertyValue({
    value,
    type
  })

  const primaryText = defaultTo(visualisation, null)
  const secondaryText = get(feature, `properties.${titleProperty.name}`, null)

  const displayProps = {
    property,
    value: formattedValue,
    primaryText,
    secondaryText,
    loading: isEmpty(feature)
  }

  return (
    <Box
      id='MapVisualisation'
      className='MapVisualisation'
      data-variant={variant}
      component={Paper}
      variant='outlined'
      sx={[
        (theme) => ({
          display: 'flex',
          flexDirection: 'column',
          p: 2,
          position: 'relative',
          borderRadius: 2,
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          [theme.breakpoints.down('mobile')]: {
            p: 1
          }
        }),

        mobile && {
          borderRadius: 1
        }
      ]}
      {...rest}
    >
      <MapVisualisationDesktopDisplay {...displayProps} />

      <MapVisualisationColorRamps
        variant={variant}
        property={property}
        jenks={jenks}
        ramps={ramps}
        visualisationType={visualisationType}
      />
    </Box>
  )
}

MapVisualisation.propTypes = {
  variant: PropTypes.oneOf(['mobile', 'desktop']),
  feature: PropTypes.object,
  visualisation: PropTypes.string,
  visualisationType: PropTypes.oneOf(Object.values(VISUALISATION)),
  properties: PropTypes.array,
  jenks: PropTypes.array,
  ramps: PropTypes.array
}

export { MapVisualisation }
export default MapVisualisation
