import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import SvgIcon from '@mui/material/SvgIcon'
import Typography from '@mui/material/Typography'
import SearchMdIcon from '@untitled-ui/icons-react/build/cjs/SearchMd'
import XIcon from '@untitled-ui/icons-react/build/cjs/X'
import { isEmpty } from 'lodash'
import { Tip } from '~ui-components/components/atoms/Tip'
import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { MapSearchModalForm } from '~ui-components/components/molecules/MapSearchModalForm'
import {
  JsonResult,
  MapSearchModalResults,
  OsmResult
} from '~ui-components/components/molecules/MapSearchModalResults'

type MapSearchModalProps = {
  open?: boolean
  value?: string
  title?: string
  jsonResults?: JsonResult[]
  osmResults?: OsmResult[]
  onClose?: () => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, value: string) => void
  onFeatureItemClick?: (event: React.MouseEvent, item: JsonResult) => void
  onLocationItemClick?: (event: React.MouseEvent, item: OsmResult) => void
}

const MapSearchModal: FC<MapSearchModalProps> = (props) => {
  const {
    open = false,
    value: initialValue = '',
    title = '',
    jsonResults = [],
    osmResults = [],
    onClose = () => {},
    onChange = () => {},
    onFeatureItemClick = () => {},
    onLocationItemClick = () => {}
  } = props

  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setValue(value)
    onChange(event, value)
  }

  if (!open) return null

  return (
    <Box
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === 'dark' ? 'neutral.800' : 'neutral.100',
        p: 3,
        '--map-search-input-height': '46px',
        outline: 'none',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        bgcolor: 'rgba(0,0,0,0.24)',
        zIndex: (theme) => theme.zIndex.modal
      }}
    >
      <Container maxWidth='sm'>
        <Paper
          elevation={12}
          sx={{ p: 0, pb: 1, overflow: 'hidden' }}
        >
          <Stack
            alignItems='center'
            direction='row'
            spacing={1}
            sx={{
              px: 2,
              py: 1
            }}
          >
            <SearchMdIcon />
            <Typography
              sx={{ flexGrow: 1 }}
              variant='h6'
            >
              Search
            </Typography>
            <IconButton
              data-testid='close-search'
              onClick={onClose}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>
          <Box sx={{ mb: 1, ml: 1 }}>
            <Tip message='Search for addresses or values in the data, e.g. “Mega Corp”' />
          </Box>
          <MapSearchModalForm onChange={handleChange} />
          <MapSearchModalResults
            title={title}
            jsonResults={jsonResults}
            osmResults={osmResults}
            open={!isEmpty(value)}
            onFeatureItemClick={onFeatureItemClick}
            onLocationItemClick={onLocationItemClick}
          />
        </Paper>
      </Container>
    </Box>
  )
}

export { MapSearchModal, MapSearchModalForm, MapSearchModalResults }
export default MapSearchModal
