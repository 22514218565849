import { Masonry } from '@mui/lab'
import { concat } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { getMapLink, getMapThumbnailLink, getWorkspaceLink } from 'utils'
import { Card } from '../Card'
import { CardSkeleton } from '../Card/Skeleton'
import EmptyMapResultFallback from '../EmptyMapResultFallback'

export const VIRTUALIZED_MAP_GRID_ITEMS_PER_ROW = 4

const VirtualizedMapGrid = (props) => {
  const { loading, items: itemsProp = [], hasMoreItems, onEndReached } = props

  const [lastCardRef, setLastCardRef] = useState<HTMLDivElement | null>(null)
  const [endReached, setEndReached] = useState(false)
  const [items, setItems] = useState(itemsProp)

  const whenEndReached = useCallback(() => {
    if (hasMoreItems) {
      setItems((current) =>
        concat(
          current,
          Array.from({ length: VIRTUALIZED_MAP_GRID_ITEMS_PER_ROW }, () => null)
        )
      )
    }
    onEndReached()
  }, [hasMoreItems, onEndReached])

  useEffect(() => {
    // console.log('🚀 ~ useEffect ~ itemsProp:', itemsProp)
    setItems(itemsProp)
  }, [itemsProp])

  useEffect(() => {
    let observer: IntersectionObserver | undefined = undefined

    if (lastCardRef) {
      observer = new IntersectionObserver(([entry]) => {
        if (entry) setEndReached(entry.isIntersecting)
      })
      observer.observe(lastCardRef)
    }

    return () => {
      observer?.disconnect?.()
    }
  }, [lastCardRef])

  useEffect(() => {
    if (hasMoreItems && !loading && endReached) {
      setLastCardRef(null)
      setEndReached(false)
      whenEndReached()
    }
  }, [hasMoreItems, loading, whenEndReached, endReached])

  if (loading && !items.length)
    return (
      <Masonry
        spacing={4}
        columns={{ xs: 1, md: 2, lg: 3, xxl: 4 }}
        sx={{ margin: 'unset', height: 'calc(1281px + 32px)' }}
      >
        {Array.from({ length: VIRTUALIZED_MAP_GRID_ITEMS_PER_ROW * 3 })
          .fill(null)
          .map((_, i) => (
            <CardSkeleton key={`skeleton-${i}`} />
          ))}
      </Masonry>
    )

  if (!loading && !items.length)
    return <EmptyMapResultFallback sx={[{ mx: 3 }]} />

  return (
    <Masonry
      spacing={4}
      columns={{ xs: 1, md: 2, lg: 3, xxl: 4 }}
      sx={{ margin: 'unset' }}
    >
      {items.map((map, i) =>
        map ? (
          <Card
            key={map.id}
            title={map.name}
            href={getMapLink({
              mapId: map.id,
              mapName: map.name
            })}
            cover={getMapThumbnailLink(map)}
            avatar={{
              image: map.workspace.logo ?? '',
              name: map.workspace.name ?? '',
              href: getWorkspaceLink({
                workspaceId: map.workspace.id,
                workspaceName: map.workspace.name
              }),
              updatedAt: map.updatedAt
            }}
            footer={{
              likes: map.likes ?? 0,
              views: map.views ?? 0
            }}
            rootCardProps={
              i === items.length - 1 ? { ref: setLastCardRef } : {}
            }
          />
        ) : (
          <CardSkeleton key={`skeleton-${i}`} />
        )
      )}
    </Masonry>
  )
}

export { VirtualizedMapGrid }
export default VirtualizedMapGrid
