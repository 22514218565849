import { Box, Container, GlobalStyles, Typography } from '@mui/material'
import PropTypes from 'prop-types'

import BrandLogo from '~ui-components/components/atoms/BrandLogo'
import AppFooter from '~ui-components/components/organisms/AppFooter'
import JoinWorkspaceCopy from '~ui-components/components/organisms/JoinWorkspacePanels/JoinWorkspaceCopy'

const Overrides = (
  <GlobalStyles
    styles={{
      'html, body, #__next': {
        height: '100%'
      }
    }}
  />
)

const JoinWorkspace = (props) => {
  const { loading, workspaceName, membersAvatarGroup, authMethods } = props

  return (
    <>
      {Overrides}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: (theme) => theme.palette.background.paper,
          py: 8
        }}
      >
        <Box
          component={Container}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <BrandLogo
            variant='logoCopy'
            size='lg'
          />
          <JoinWorkspaceCopy
            loading={loading}
            workspaceName={workspaceName}
          />
          <Box>{membersAvatarGroup}</Box>
        </Box>
      </Box>

      <Box
        component={Container}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          py: 8
        }}
      >
        <Box
          component={Typography}
          textAlign='center'
          color='text.secondary'
        >
          We suggest using the email account you use for work.
        </Box>
        <Box
          my={4}
          width={{
            xs: '100%',
            sm: '400px'
          }}
        >
          {authMethods}
        </Box>
      </Box>

      <AppFooter
        pb={2}
        marginTop='auto'
      />
    </>
  )
}

const JoinWorkspacePropTypes = {
  loading: PropTypes.bool,
  workspaceName: PropTypes.string,
  membersAvatarGroup: PropTypes.node,
  authMethods: PropTypes.node
}

JoinWorkspace.propTypes = JoinWorkspacePropTypes

export { JoinWorkspace, JoinWorkspacePropTypes }
export default JoinWorkspace
