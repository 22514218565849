import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Popover from '@mui/material/Popover'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import toast from 'react-hot-toast'
import { User, Workspace } from '~ui-components/types/__generated/gql/graphql'
import { Scrollbars } from 'react-custom-scrollbars'
import type { FC } from 'react'

export type AccountPopoverUser = Pick<
  User,
  'id' | 'firstName' | 'lastName' | 'email'
>

export type AccountPopoverWorkspace = Pick<Workspace, 'id' | 'name' | 'logo'>

export interface AccountPopoverProps {
  anchorEl: null | Element
  onClose?: () => void
  open?: boolean
  user?: AccountPopoverUser
  workspaces?: AccountPopoverWorkspace[]
  onLogout?: () => void
  slots: {
    Logo?: FC
    Workspaces?: FC
    Workspace?: FC
    Community?: FC
    UserProfile?: FC
  }
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, onLogout, slots } = props

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.()
      onLogout?.()
    } catch (err) {
      console.error(err)
      toast.error('Something went wrong!')
    }
  }, [onClose, onLogout])

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      slotProps={{
        paper: {
          sx: {
            minWidth: 200,
            maxWidth: 360,
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
            maxHeight: 'calc(100dvh - 24px)',
            overflow: 'hidden'
          }
        }
      }}
    >
      <Scrollbars
        autoHeight={true}
        autoHeightMin={0}
        autoHeightMax={'calc(100dvh - 100px)'}
        thumbMinSize={30}
        universal={true}
      >
        {slots.Workspaces && <slots.Workspaces />}
        <Divider />
        <Box sx={{ p: 2 }}>{slots.Workspace && <slots.Workspace />}</Box>
        <Divider />
        <Box sx={{ p: 2 }}>{slots.Community && <slots.Community />}</Box>
        <Divider />
        <Box sx={{ p: 1, pl: 2 }}>
          {slots.UserProfile && <slots.UserProfile />}
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            p: 1,
            justifyContent: 'center'
          }}
        >
          <Button
            data-testid='AccountPopover-LogoutButton'
            color='inherit'
            onClick={handleLogout}
            size='small'
            variant='text'
          >
            Logout
          </Button>
        </Box>
      </Scrollbars>
    </Popover>
  )
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
}
