import { isEmpty } from 'lodash'
import { baseUrl, getMapLink } from 'utils'
import { useParentMap } from '~map-viewer/states/map'

export const useCanonical = () => {
  const parentMap = useParentMap()

  if (isEmpty(parentMap)) return
  return (
    baseUrl() + getMapLink({ mapId: parentMap.id, mapName: parentMap.name })
  )
}
