import { FeatureCollection } from 'geojson'
import { Fill, Legend, Line, StyleOptions, StyleOutput } from '../../types'
import { MS_COLOUR_PALETTE } from '../constants'
import { generateClasses } from '../get-class-breaks'
import { generateColourPalette } from '../get-colour-palette'
import { generateResolutions } from '../utils/get-resolutions'
import { generate3DFill, generateFill, generateLegend, generateLine } from './boundary-style-utils'

const DEFAULT_BOUNDARY_OPTIONS: StyleOptions = {
  aggregateBoundary: 'MS_us-states',
  aggregateProperty: 'count',
  aggregateType: 'SUM',
  source: 'mapstack',
  sourceLayer: 'us:states'
}

function getBoundaryStyle(
  geojson: FeatureCollection,
  options: StyleOptions = DEFAULT_BOUNDARY_OPTIONS
): StyleOutput {
  const { boundaryClassBreaks, jenksBreaks } = generateClasses(
    geojson.features,
    options.aggregateBoundary,
    options.aggregateProperty,
    options.aggregateType!
  )

  const colourPalette = generateColourPalette(
    jenksBreaks?.length,
    MS_COLOUR_PALETTE
  )

  const fill: Fill = generateFill(
    boundaryClassBreaks,
    colourPalette,
    'mscode',
    options.source,
    options.sourceLayer
  )

  const filter =
    boundaryClassBreaks.length == 1 && boundaryClassBreaks[0]
      ? ['in', 'mscode', ...boundaryClassBreaks[0]]
      : [
        'in',
        'mscode',
        ...boundaryClassBreaks.reduce((acc, val) => [...acc, ...val], [])
      ]

  const line: Line = generateLine(options.source, options.sourceLayer, filter)

  const legend: Legend = generateLegend(
    jenksBreaks,
    colourPalette,
    options.aggregateProperty
  )

  const styleOutput: StyleOutput = {
    fill: fill,
    line: line,
    legend: legend
  }

  return styleOutput
}

// resolution is needed for height gen, is returned alongside h3 geojson generation
// -1 by default and will gen on the fly if not passed
function get3dBoundaryStyle(
  geojson: FeatureCollection,
  resolution: number = -1,
  options: StyleOptions = DEFAULT_BOUNDARY_OPTIONS
): StyleOutput {
  if (resolution === -1)
    resolution = generateResolutions(geojson.features).medium
  const { boundaryClassBreaks, jenksBreaks } = generateClasses(
    geojson.features,
    options.aggregateBoundary,
    options.aggregateProperty,
    options.aggregateType!
  )

  const colourPalette: string[] = generateColourPalette(
    jenksBreaks?.length,
    MS_COLOUR_PALETTE
  )

  const filter =
    boundaryClassBreaks.length == 1 && boundaryClassBreaks[0]
      ? ['in', 'mscode', ...boundaryClassBreaks[0]]
      : [
        'in',
        'mscode',
        ...boundaryClassBreaks.reduce((acc, val) => [...acc, ...val], [])
      ]

  const fill: Fill = generate3DFill(
    boundaryClassBreaks,
    colourPalette,
    'mscode',
    options.source,
    resolution,
    options.sourceLayer,
    filter
  )

  const legend: Legend = generateLegend(
    jenksBreaks,
    colourPalette,
    options.aggregateProperty
  )

  const styleOutput: StyleOutput = {
    fill: fill,
    legend: legend
  }
  return styleOutput
}

export { get3dBoundaryStyle, getBoundaryStyle }

