import { MS_BASIC_COLOUR } from '../../../constants';

export function generateFill(
  source: string,
  sourceLayer?: string,
  filter?
) {
  const fill = {
    "id": "Mapstack Visualization Fill",
    "type": "fill",
    "source": source,
    "paint": {
      'fill-color': MS_BASIC_COLOUR
    }
  }
  if (sourceLayer) fill['source-layer'] = sourceLayer
  if (filter) fill['filter'] = filter
  return fill
}
