import { createSlice } from '@reduxjs/toolkit'
import { findIndex } from 'lodash'
import { useSelector } from 'react-redux'

const initialState = {
  selectedProperty: {},
  activeFilters: 0,
  filters: [],
  filterResults: [],
  /**
   * Array of msuuid from filters
   */
  highlightIds: []
}

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setActiveFilters: (state, action) => {
      state.activeFilters = action.payload
    },
    setSelectedProperty: (state, action) => {
      state.selectedProperty = action.payload
    },
    removeFilter: (state, action) => {
      state.filters = state.filters.filter(
        (filter) => filter.id !== action.payload
      )
    },
    addFilters: (state, action) => {
      state.filters.push(...action.payload)
    },
    clearFilters: (state) => {
      state.filters = []
    },
    updateFilter: (state, action) => {
      const index = findIndex(state.filters, { id: action.payload.id })
      if (index === -1) state.filters.push(action.payload)
      state.filters.splice(index, 1, action.payload)
    },
    setFilterResults: (state, action) => {
      state.filterResults = action.payload
    },
    setHighlightIds: (state, action) => {
      return {
        ...state,
        highlightIds: action.payload
      }
    },
    clearHighlightIds: (state) => {
      return {
        ...state,
        highlightIds: []
      }
    }
  }
})

const selectHighlightIds = (state) => state.filter.highlightIds
const selectFilters = (state) => state.filter.filters
const selectFilterResults = (state) => state.filter.filterResults
const selectSelectedProperty = (state) => state.filter.selectedProperty
const selectActiveFilters = (state) => state.filter.activeFilters
const selectHasFilters = (state) => state.filter.filters.length > 0

export const useHighlightIds = () => useSelector(selectHighlightIds)

export const useFilters = () => useSelector(selectFilters)

export const useFilterResults = () =>
  useSelector((state) => {
    return state.filter.filterResults
  })

export const useSelectedProperty = () => useSelector(selectSelectedProperty)

export const useActiveFilters = () => useSelector(selectActiveFilters)

export const useHasFitlers = () => useSelector(selectHasFilters)

export const {
  setActiveFilters,
  setSelectedProperty,
  setFilterResults,
  addFilters,
  removeFilter,
  clearFilters,
  updateFilter,
  setHighlightIds,
  clearHighlightIds
} = filterSlice.actions

export default filterSlice.reducer
