import {
  Avatar,
  Button,
  Dialog,
  Paper,
  Stack,
  SvgIcon,
  Typography
} from '@mui/material'
import { LinkBroken01 } from '@untitled-ui/icons-react'
import { useRouter } from 'next/router'

const AppErrorDialog = (props) => {
  const { open, onActionClick, ...rest } = props

  const router = useRouter()

  const handleActionClick = () => {
    onActionClick?.()
    router.reload()
  }

  return (
    <Dialog
      maxWidth='sm'
      open={open}
      {...rest}
      sx={{
        p: 2
      }}
    >
      <Paper
        component={Stack}
        spacing={2}
        elevation={12}
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}
      >
        <Avatar
          sx={{
            backgroundColor: 'primary.lightest',
            color: 'primary.main',
            width: 60,
            height: 60
          }}
        >
          <SvgIcon fontSize='large'>
            <LinkBroken01 />
          </SvgIcon>
        </Avatar>

        <Typography variant='h5'>Oops! Something Went Wrong</Typography>

        <Typography
          align='center'
          color='text.secondary'
          sx={{ mt: 1 }}
          variant='body2'
        >
          An unexpected error has occurred. Please try reloading the page or
          come back later. If the issue persists, contact our support team for
          assistance.
        </Typography>

        <Button
          fullWidth
          color='primary'
          size='large'
          variant='contained'
          onClick={handleActionClick}
        >
          Reload page
        </Button>
      </Paper>
    </Dialog>
  )
}

export { AppErrorDialog }
