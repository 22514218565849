import { Box, useMediaQuery, useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { SearchInput } from '~ui-components/components/molecules/SearchInput'
import { SearchIcon } from 'ui-components'
import { on } from 'events'

const useResponsive = () => {
  const theme = useTheme()
  const desktop = useMediaQuery(theme.breakpoints.up(758))
  const mobile = !desktop
  return { mobile, desktop }
}

const AppHeaderSearch = ({ onOpen }) => {
  const { mobile } = useResponsive()

  if (mobile) return <SearchIcon onClick={onOpen} />

  return (
    <Box onClick={onOpen}>
      <SearchInput
        cursor='pointer'
        autoFocus={false}
      />
    </Box>
  )
}

AppHeaderSearch.propTypes = {
  value: PropTypes.any,
  results: PropTypes.array,
  onChange: PropTypes.func,
  onSearchClick: PropTypes.func,
  onSubmit: PropTypes.func
}

export { AppHeaderSearch }
export default AppHeaderSearch
