import { Box } from '@mui/material'
import NextImage from 'next/legacy/image'
import { lostGif } from '~ui-components/assets/images'
import {
  Dialog,
  DialogPropTypes
} from '~ui-components/components/molecules/Dialog'

const WorkspaceNotFoundDialog = (props) => {
  return (
    <Dialog
      title="Sorry we couldn't find that workspace"
      noCancel
      noConfirm
      {...props}
    >
      <Box sx={{ width: '100%', borderRadius: '4px', overflow: 'hidden' }}>
        <NextImage
          src={lostGif}
          layout='responsive'
          objectFit='fill'
          width={700}
          height={475}
        />
      </Box>
    </Dialog>
  )
}

WorkspaceNotFoundDialog.propTypes = DialogPropTypes

export { WorkspaceNotFoundDialog }
export default WorkspaceNotFoundDialog
