import { FC } from 'react'
import {
  LayerProps as MapLibreLayerProps,
  Layer as MapLibreLayer
} from 'react-map-gl/maplibre'

export type LayerProps = MapLibreLayerProps

export const Layer: FC<LayerProps> = (props) => {
  return <MapLibreLayer {...props} />
}
