import { DEAFULT_OTHER_CATEGORY_COLOUR } from '../../../constants'
import { Legend, LegendOptions } from '../../../../types'

export function generateLegend(
  categories: string[],
  colourPalette: string[],
  title: string
): Legend {
  const classBreakDescriptions: LegendOptions[] = []

  // handle edge case of single class
  if (categories.length == 1 && colourPalette[0])
    return {
      title: title,
      classes: [
        {
          value: colourPalette[0],
          key: 'fill',
          label: categories[0]!
        }
      ]
    }

  for (let i = 0; i < categories.length; i++) {
    const label: string = categories[i]!

    const colour: string | undefined = colourPalette[i]
    if (!colour) {
      throw new Error('Unable to find colour')
    }

    if (colour !== DEAFULT_OTHER_CATEGORY_COLOUR) {
      const legendEntry: LegendOptions = {
        value: colour,
        key: 'fill',
        label: label
      }
      classBreakDescriptions.push(legendEntry)
    } else {
      const legendEntry: LegendOptions = {
        value: colour,
        key: 'fill',
        label: 'Other'
      }
      classBreakDescriptions.push(legendEntry)
      break
    }
  }

  const legend: Legend = {
    title: title,
    classes: classBreakDescriptions
  }

  return legend
}
