/* eslint-disable @typescript-eslint/ban-ts-comment */
import SvgIcon from '@mui/material/SvgIcon'
import Geojson from '@untitled-ui/icons-react/build/esm/Brackets'
import MapAddress from '@untitled-ui/icons-react/build/esm/MarkerPin04'
import BoundaryData from '@untitled-ui/icons-react/build/esm/Perspective01'
import { useRouter } from 'next/router'
import { Products as View } from 'ui-components'

type ProductsProps = {
  navList?: boolean
}

const Products = (props) => {
  const { navList = false } = props
  const router = useRouter()
  return (
    <div>
      <View
        navList={navList}
        label='Products'
        onChange={(value) => router.push(`/${value}`)}
        options={productOptions}
      />
    </div>
  )
}

interface Option {
  label: string
  value: string
  icon: () => JSX.Element
}

export const productOptions: Option[] = [
  {
    value: 'make-a-map-from-addresses',
    label: 'Map address data',
    icon: () => (
      <SvgIcon>
        <MapAddress />
      </SvgIcon>
    )
  },
  {
    value: 'make-a-map-from-data',
    label: 'Map boundary data',
    icon: () => (
      <SvgIcon>
        <BoundaryData />
      </SvgIcon>
    )
  },
  {
    value: 'make-a-map-from-geojson',
    label: 'Map spatial data',
    icon: () => (
      <SvgIcon>
        <Geojson />
      </SvgIcon>
    )
  }
]

export { Products }
