import { Fill, Line, StyleOptions, StyleOutput } from '../../types'
import { generateFill, generateLine } from './basic-style-utils'

const DEFAULT_BOUNDARY_OPTIONS: StyleOptions = {
  aggregateBoundary: 'MS_us-states',
  aggregateProperty: 'count',
  source: 'mapstack',
  sourceLayer: 'us:states'
}

function getBasicStyle(
  options: StyleOptions = DEFAULT_BOUNDARY_OPTIONS
): StyleOutput {

  const fill: Fill = generateFill(
    options.source,
    options.sourceLayer,
    options.filter
  )

  const line: Line = generateLine(
    options.source,
    options.sourceLayer,
    options.filter)

  const styleOutput: StyleOutput = {
    fill: fill,
    line: line
  }

  return styleOutput
}

export { getBasicStyle }

