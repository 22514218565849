import { AppHeaderLogo } from 'ui-components'
import { useAuthContext } from 'user-auth'

export const Logo = () => {
  const { authenticated } = useAuthContext()

  return (
    <AppHeaderLogo appNavigationLogoHref={authenticated ? '/search' : '/'} />
  )
}
