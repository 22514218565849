import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { CloudUploadPlugin, TextEditor as Editor } from 'ui-components'
import { useAuthContext } from 'user-auth'
import { createPresignedPostUpload } from 'utils'
import { createGetReadmeAssetsUploadUrl } from '~map-viewer/api/createGetReadmeAssetsUploadUrl'

import { useMap } from '~map-viewer/states/map'
import { Footer } from './Footer'

const TextEditor = (props) => {
  const { slots, ...rest } = props

  const map = useMap()
  const { token } = useAuthContext()

  /**
   * createFileUpload must return upload and abort so
   * the text editor could use those function when create a new image,
   * and abort image upload.
   */
  const createFileUpload = useCallback(
    (file) => {
      if (!token) throw new Error('Token is required')

      const uploadController = new AbortController()

      const getReadmeAssetsUploadUrl = createGetReadmeAssetsUploadUrl({
        mapId: map.id,
        contentType: file.type
      })

      return {
        upload: async (config) => {
          // presignedPost is a AWSJSON! and need to convert to json object
          const { path, presignedPost } = await getReadmeAssetsUploadUrl.promise

          const upload = createPresignedPostUpload(JSON.parse(presignedPost))

          await upload(file, {
            signal: uploadController.signal,
            ...config
          })

          return { path }
        },

        abort: () => {
          uploadController.abort()
          getReadmeAssetsUploadUrl.abort()
        }
      }
    },
    [map?.id, token]
  )

  return (
    <Editor
      {...rest}
      initialValue={map?.readme?.nodes}
      slots={{
        ...slots,
        Footer
      }}
      plugins={[
        CloudUploadPlugin({
          options: {
            createFileUpload
          }
        })
      ]}
    />
  )
}

TextEditor.propTypes = {
  slots: PropTypes.object
}

export { TextEditor }
export default TextEditor
