import axios from 'axios'
import type { AxiosRequestConfig } from 'axios'

type PresignedPost = {
  url: string
  fields: Record<string, string>
}

const createPresignedPostUpload = (presignedPost: PresignedPost) => {
  return (file: File, config?: AxiosRequestConfig) => {
    const { url, fields } = presignedPost

    const formData = new FormData()

    Object.entries(fields).forEach(([k, v]) => formData.append(k, v))

    formData.append('Content-Type', file.type)
    formData.append('file', file) // must be the last one

    return axios.post(url, formData, config)
  }
}

export { createPresignedPostUpload }
