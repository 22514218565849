import { MS_DEFAULT_COLOUR } from '../constants/index';
/**
 * Converts a HEX color to RGBA format.
 * @param hex The HEX color code.
 * @param opacity The opacity level (0-1).
 * @returns The color in RGBA format.
 */
function hexToRGBA(hex: string, opacity: number): string {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}

function unique(arr) {
  const uniqueArray: any[] = [];
  const seen = {};

  for (const num of arr) {
    if (!seen[num]) {
      uniqueArray.push(num);
      seen[num] = true;
    }
  }
  return uniqueArray;
}

function generateColourRamp(
  dataClassBreaks: Array<string[] | number[]>,
  colourPalette: string[]
) {

  const colourRamp: any[] = []

  for (let i = 0; i < dataClassBreaks.length; i++) {
    const dataValues: string[] | number[] | undefined = unique(dataClassBreaks[i])
    const colourValue: string | undefined = colourPalette[i]
    if (!dataValues || dataValues === undefined) {
      throw new Error('Unable to gnerate Colour Ramp')
    }
    if (dataValues.length !== 0) { // workaround for non-continous data having class breaks on consecutive values e.g ints w/ class breaks 0,2,3,4,7,9 
      colourRamp.push(dataValues)
      colourRamp.push(colourValue)
    }
  }
  // Convert default color to RGBA with opacity 0
  const transparentDefaultColour = hexToRGBA(MS_DEFAULT_COLOUR, 0)
  colourRamp.push(transparentDefaultColour)

  return colourRamp
}

function generateCategoryColourRamp(featureCategoryRecord: { [s: string]: any; }, colourPalette: string[]) {
  const colourRamp: any[] = []
  const idArrays = Object.values(featureCategoryRecord)
  for (let idx = 0; idx < colourPalette.length; idx++) {
    colourRamp.push(idArrays[idx])
    colourRamp.push(colourPalette[idx])
  }

  // Convert default color to RGBA with opacity 0
  const transparentDefaultColour = hexToRGBA(MS_DEFAULT_COLOUR, 0)
  colourRamp.push(transparentDefaultColour)

  return colourRamp
}

export {
  generateColourRamp,
  generateCategoryColourRamp
}
