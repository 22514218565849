import Observable from 'zen-observable-ts'
import { AWSAppSyncRealTimeProvider } from '@aws-amplify/pubsub'
import { API } from 'aws-amplify'
import {
  graphqlOperation,
  GraphQLResult,
  GraphQLSubscription
} from '@aws-amplify/api'
import { GraphQLOptions } from '@aws-amplify/api-graphql'

/** A simple typesafe helper to make appsync subscription easier */
export const graphierQLSubscription = async <
  TResults = unknown,
  TArgs extends Record<string, any> = Record<string, any>
>(
  _opts: Pick<GraphQLOptions, 'query'> & {
    variables?: TArgs
  },
  {
    onNext,
    onError
  }: {
    onNext: (value: GraphQLResult<TResults>) => void
    onError: (error) => void
  }
) => {
  return (
    API.graphql<GraphQLSubscription<TResults>>(
      graphqlOperation(_opts.query, _opts.variables)
    ) as Observable<{
      provider: AWSAppSyncRealTimeProvider
      value: GraphQLResult<TResults>
    }>
  ).subscribe({
    next: ({ value }) => onNext(value),
    error: (error) => onError(error)
  })
}
