import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { GetUserByIdQuery } from '~user-auth/types/__generated/gql/graphql'
import { graphierQL } from 'utils'

const query = /* GraphQL */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      uid
      firstName
      lastName
      email
      avatar
      createdAt
      defaultWorkspaceId
      defaultWorkspace {
        id
        name
      }
      membershipCollection {
        total
        items {
          role
          workspace {
            id
            logo
            name
            description
            createdAt
            memberCollection {
              total
              items {
                role
                user {
                  id
                  firstName
                  lastName
                  email
                  avatar
                }
              }
            }
          }
        }
      }
    }
  }
`

export type GetUserByIdResults = GetUserByIdQuery['getUserById']

export const getUserByIdQueryKey = (id: string) => {
  return ['useGetUserById', id]
}

export const getUserByIdQueryFn = (id: string) =>
  graphierQL<GetUserByIdQuery>({
    query,
    variables: { id },
    useToken: true
  }).then((res) => res.getUserById)

export const useGetUserById = (
  id?: string
): UseQueryResult<GetUserByIdResults> => {
  return useQuery({
    enabled: !!id,
    queryKey: getUserByIdQueryKey(id!),
    queryFn: () => getUserByIdQueryFn(id!)
  })
}
