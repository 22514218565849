import { MapButtons as Layout } from 'ui-components'
import { useIsOwner, useMapStatus } from '~map-viewer/states/map'

import { useAuthContext } from 'user-auth'
import { MapStatus } from '~map-viewer/constants/MapStatus'

import { DeleteButton } from './DeleteButton'
import { DownloadButton } from './DownloadButton'
import { LikeButton } from './LikeButton'
import { RemixButton } from './RemixButton'
import { SettingButton } from './SettingButton'
import { ShareButton } from './ShareButton'

function MapButtons(props) {
  const { authenticated } = useAuthContext()
  const isOwner = useIsOwner()
  const status = useMapStatus()

  const mapReady = status === MapStatus.READY
  const ready = authenticated && mapReady

  return (
    <Layout
      {...props}
      owned={isOwner}
      slots={{
        DeleteButton,
        DownloadButton,
        LikeButton,
        RemixButton,
        SettingButton,
        ShareButton
      }}
      slotProps={{
        RemixButton: {
          remixable: ready
        }
      }}
    />
  )
}

export { MapButtons }
