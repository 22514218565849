import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { useAuthContext } from 'user-auth'
import { QueryProvider } from '~header/providers/QueryProvider'
import { AnonymousHeader } from './AnonymousHeader'
import { SignedInHeader } from './SignedInHeader'

type HeaderProps = {
  anonymousOnly?: boolean
  showSearch?: boolean
}

const Header: FC<HeaderProps> = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const { anonymousOnly = false, showSearch = false } = props
  const { authenticated, loading } = useAuthContext()

  useEffect(() => {
    setIsLoading(loading)
  }, [loading])

  return (
    <QueryProvider>
      {authenticated && !anonymousOnly ? (
        <SignedInHeader
          loading={isLoading}
          showSearch={showSearch}
        />
      ) : (
        <AnonymousHeader
          loading={isLoading}
          showSearch={showSearch}
        />
      )}
    </QueryProvider>
  )
}

export { Header }
