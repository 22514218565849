import { Box, Divider, SvgIcon, Tab, Tabs } from '@mui/material'
import Map01 from '@untitled-ui/icons-react/build/esm/Map01'
import Settings01 from '@untitled-ui/icons-react/build/esm/Settings01'
import User01 from '@untitled-ui/icons-react/build/esm/User01'

import Skeleton from '~ui-components/components/atoms/Skeleton'

export enum WORKSPACE_TAB {
  MAPS = 'maps',
  MEMBERS = 'members',
  SETTINGS = 'settings'
}

const WorkspaceTabBar = (props) => {
  const {
    canAccessWorkspaceMember,
    canUpdateWorkspaceSettings = false,
    totalMaps = 0,
    totalMembers = 0,
    loading = false,
    currentTab = WORKSPACE_TAB.MAPS,
    onTabChange
  } = props

  const tabs = [
    {
      id: 'MapListMapsTab',
      'data-testid': 'MapsTab',
      label: totalMaps ? `Maps (${totalMaps})` : 'Maps',
      value: WORKSPACE_TAB.MAPS,
      icon: (
        <SvgIcon>
          <Map01 />
        </SvgIcon>
      )
    }
  ]

  if (canAccessWorkspaceMember) {
    tabs.push({
      id: 'MapListMembersTab',
      'data-testid': 'MembersTab',
      label: totalMembers ? `People (${totalMembers})` : 'People',
      value: WORKSPACE_TAB.MEMBERS,
      icon: (
        <SvgIcon>
          <User01 />
        </SvgIcon>
      )
    })
  }

  if (canUpdateWorkspaceSettings) {
    tabs.push({
      id: 'MapListSettingsTab',
      'data-testid': 'SettingsTab',
      label: 'Settings',
      value: WORKSPACE_TAB.SETTINGS,
      icon: (
        <SvgIcon>
          <Settings01 />
        </SvgIcon>
      )
    })
  }

  return loading ? (
    <Box
      display='flex'
      flexDirection='row'
      flexWrap='nowrap'
      gap={1}
      sx={{
        overflowX: 'hidden'
      }}
    >
      {Array.from({ length: 4 }).map((_, index) => {
        return (
          <Skeleton
            loading
            variant='square'
            key={index}
            width={122}
            height={40}
          />
        )
      })}
    </Box>
  ) : (
    <Box>
      <Tabs
        data-testid='WorkspaceTabBar'
        indicatorColor='primary'
        onChange={onTabChange}
        scrollButtons='auto'
        sx={{ px: 3 }}
        textColor='primary'
        value={currentTab}
        variant='scrollable'
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            icon={tab.icon}
          />
        ))}
      </Tabs>
      <Divider />
    </Box>
  )
}

export { WorkspaceTabBar }
