import { useIsMobile } from 'ui-components'

export const UseWidth = (isEmbed = false) => {
  const isMobile = useIsMobile()

  const width = isEmbed
    ? isMobile
      ? 'calc(100% - 80px)'
      : 380
    : isMobile
    ? '100%'
    : 380
  const left = isEmbed || !isMobile ? 80 : 0
  return {
    width,
    left
  }
}
